import React, { Component, createContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";

import './Home.scss';
import logo from './assets/logo-korindo.png';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Landing from './pages/LandingPage';
import Registration from './pages/RegistrationPage';
import RegistrationStatus from './pages/RegistrationStatus';
import Citizenship from './pages/CitizenshipPage';
import Submitted from './pages/SubmittedPage';
import Privacy from './pages/PrivacyPage';
import Summary from './pages/SummaryPage';
import Maintenance from './pages/MaintenancePage';
// import Legal from './pages/LegalPage';
import PdfViewer from './pages/PdfViewerPage';
import Language from './components/mini/Language';

import YouTube from 'react-youtube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import queryString from 'query-string';
import validate from './pages/validate';
import { translations } from './language/translations';
import publicIp from "public-ip";

function search(nameKey, myArray) {
	if (myArray) {
		for (var i = 0; i < myArray.length; i++) {
			if (myArray[i].value === nameKey) {
				return myArray[i];
			}
		}
	}
}

const link_api = (window.location.host === 'openaccount.nhsec.co.id') ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;
// const link_api = "http://192.168.1.100:8080";

const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
}

const headers = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
	}
}

const LayoutFooter = (props) => {
	let facebook = '';
	let instagram = '';
	let twitter = '';
	let company_name = '© 2020 ';
	if (props.data_social && props.data_social.length > 0) {
		facebook = "https://www.facebook.com/" + props.data_social.find(({ key }) => key === 'facebook').value;
		instagram = "https://www.instagram.com/" + props.data_social.find(({ key }) => key === 'instagram').value;
		twitter = "https://www.twitter.com/" + props.data_social.find(({ key }) => key === 'twitter').value;
	}
	if (props.data_web && props.data_web.length > 0) {
		company_name += props.data_web.find(({ key }) => key === 'company_name').value;
	}
	return (
		<div className="sl-bottom">
			<div className="column">
				<div className="social-media">
					{
						facebook ?
							<a href={facebook} rel="noopener noreferrer" target="_blank" className="link-socmed">
								<FontAwesomeIcon className="icon" icon={faFacebook} />
							</a>
							: ''
					}
					{
						instagram ?
							<a href={instagram} rel="noopener noreferrer" target="_blank" className="link-socmed">
								<FontAwesomeIcon className="icon" icon={faInstagram} />
							</a>
							: ''
					}
					{
						twitter ?
							<a href={twitter} rel="noopener noreferrer" target="_blank" className="link-socmed">
								<FontAwesomeIcon className="icon" icon={faTwitter} />
							</a>
							: ''
					}
				</div>
				<div>
					<span className="copyright">{company_name ? company_name : ''}</span>
				</div>
			</div>
			<div className="column">
				<Link target="_blank" to="/legal-privacy" className="link-bot">{props.legal_privacy}</Link>
			</div>
		</div>
	);
}

export const RootContext = createContext();
const Provider = RootContext.Provider;
const initRegis = {
	auth_token: '',
	first_name: '',
	middle_name: '',
	last_name: '',
	nationality: '',
	place_of_birth: '',
	date_of_birth: '',
	sex: '',
	marital_status: '',
	spouse_name: '',
	mother_maiden_name: '',
	religion: '',
	religion_other: '',
	heir_name: '',
	heir_relation: '',
	educational_background: '',
	income_per_annum: '',
	source_of_fund: '',
	source_of_fund_others: '',
	investment_objectives: '',
	asset_owner: '',

	occupation: '',
	// nature_of_business: '',
	// occupation_others: '',
	job_position: '',
	field_of_business: '',
	monthly_income_range: '',
	work_year: '',
	work_month: '',
	company_name: '',
	company_country: '',
	company_province: '',
	company_city: '',
	company_postal_code: '',
	company_phone_area_code: '',
	company_phone_number: '',
	company_rt: '',
	company_rw: '',
	company_subdistrict: '',
	company_district: '',

	company_subdistrict_master: '',
	company_district_master: '',	

	pos_full_name: '',
	pos_place_of_birth: '',
	pos_dob: '',
	pos_home_phone: '',
	pos_mobile_phone: '',
	pos_address: '',
	pos_province: '',
	pos_city: '',
	pos_postalcode: '',
	pos_id_type: '',
	pos_idcard_no: '',
	pos_idcard_expdate: '',
	pos_occupation_code: '',
	pos_occupation_name: '',
	pos_company_name: '',
	pos_company_address: '',
	pos_company_province: '',
	pos_company_city: '',
	pos_company_postalcode: '',
	pos_company_phone_number: '',
	pos_company_fax: '',
	pos_annual_income: '',
	pos_source_of_income: '',
	pos_gender: '',
	pos_marital_status: '',
	pos_family_relationship: '',
	pos_email_address: '',
	pos_rt: '',
	pos_rw: '',
	pos_district: '',
	pos_subdistrict: '',
	pos_npwp_number: '',
	pos_no_npwp_reason: '',

	pos_ktp_file: '',
	pos_ktp_path: '',

	pos_npwp_file: '',
	pos_npwp_path: '',

	ktp_number: '',
	ktp_expired_date: '',
	ktp_file: '',
	ktp_path: '',
	npwp_number: '',
	npwp_registration_date: '',
	npwp_file: '',
	npwp_path: '',
	no_npwp_reason: '',
	passport_number: '',
	passport_file: '',
	passport_path: '',
	passport_expired_date: '',
	kitas: '',
	kitas_file: '',
	kitas_path: '',
	kitas_expired_date: '',
	self_photo_file: '',
	self_photo_path: '',

	address_1: '',
	address_2: '',
	address_3: '',
	country: '',
	province: '',
	city: '',
	rt: '',
	rw: '',
	district: '',
	subdistrict: '',
	postal_code: '',
	home_phone: '',
	home_phone_area_code: '',
	mobile_phone: '',
	mobile_phone_country_code: '',
	mobile_phone_country_dialcode: '',
	fax_number: '',
	email_address: '',
	hrel_name: '',
	hrel_address: '',
	hrel_telp: '',
	hrel_telp_country_code: '',
	hrel_telp_country_dialcode: '',
	hrell_type: '',

	// other_contact_address_1: '',
	// other_contact_address_2: '',
	// other_contact_address_3: '',
	// other_contact_country: '',
	// other_contact_province: '',
	// other_contact_city: '',
	// other_contact_postal_code: '',
	// other_contact_home_phone: '',
	// other_contact_mobile_phone: '',
	// other_contact_fax_number: '',
	// other_contact_email_address: '',

	bank_name: '',
	bank_name_others: '',
	rdn_bank: '',
	bank_book_file: '',
	bank_book_path: '',
	account_number: '',
	bank_bic_code: '',
	account_holder_name: '',
	account_currency: '0',
	signature_raw: '',
	signature_path: '',
	bca_agree1: '',
	bca_agree2: '',
	bca_agree3: '',
	cimb_agree1: '',
	cimb_agree2: '',

	// bank_name_2: '',
	// account_number_2: '',
	// bank_bic_code_2: '',
	// account_holder_name_2: '',
	// account_currency_2: '',

	// bank_name_3: '',
	// account_number_3: '',
	// bank_bic_code_3: '',
	// account_holder_name_3: '',
	// account_currency_3: '',
};

export default class Home extends Component {
	state = {
		lang: 'id',
		step: 1,
		member_id: '',
		livenessSubmitCount: 0,
		next_action: false,
		process: false,
		maintenance_mode: null,
		citizenship: {
			identitynumber: (process.env.REACT_APP_IS_LOCAL_ENVIRONMENT) ? process.env.REACT_APP_DUMMY_ACCOUNT_CARDID : '',
			fullname: (process.env.REACT_APP_IS_LOCAL_ENVIRONMENT) ? process.env.REACT_APP_DUMMY_ACCOUNT_FULLNAME : '',
			dob: (process.env.REACT_APP_IS_LOCAL_ENVIRONMENT) ? process.env.REACT_APP_DUMMY_ACCOUNT_BIRTHDAY : '',
			foreigner: false,
			otp: '',
			email: '',
			phonenumber: '',
		},
		registration: initRegis,
		ref_id: '',
		ip_address: '',
		referral_url: '',
		user_agent: '',
		user_id: '',
		user_id_editable: true,
		show_ref_field: false,

		submitted: {
			submitted: '',
			identitynumber: '',
			fullname: '',
			dob: '',
			ksei_status: '',
			ksei_sre: '',
			ksei_sid: '',
			rdn_status: '',
			rdn_bank: '',
			rdn_number: '',
			rdn_pdf_form_path: '',
			form_application_doclink: '',
			application_form_pdf_path: '',
			term_service_doclink: '',
			term_service_pdf_path: '',
		},

		errors: {
			global_type: '',
			global_message: '',
		},
		master: {
			sex: [{ value: 'male', label: 'master_gender_1' }, { value: 'female', label: 'master_gender_2' }],
			asset_owner: [{ value: '1', label: 'Myself' }, { value: '2', label: 'Representing Other Party' }],
			master_marital_status: [],
			master_marital_status_cimb: [
				{value: 1, label: 'Belum Menikah'},
				{value: 2, label: 'Menikah'},
				{value: 3, label: 'Cerai'},
				{value: 4, label: 'Duda/Janda'}
			],
			master_educational_background_ksei: [],
			master_occupation_ksei: [],
			master_occupation_bca: [],
			master_income_per_annum_ksei: [],
			master_monthly_income_range: [],
			master_source_of_fund: [],
			master_investment_objectives: [],
			master_nationality_ksei: [],
			master_city_ksei: [],
			master_country: [],
			master_province_ksei: [],
			master_family_relationship_cimb: [],
			master_no_npwp_reason: [{"value": "1", "label": "Belum memiliki/mengajukan NPWP"}, {"value": "2", "label": "Tidak diwajibkan memiliki NPWP sesuai dengan ketentuan undang-undang dan juga tidak memiliki BO (Beneficial Owner)"}, {"value": "3", "label": "Telah memiliki NPWP namun tidak dibawa / hilang / alasan lain dan nomor tidak ingat"}],
			master_id_type: [{ "value": "1", "label": "KTP" }, { "value": "2", "label": "Passport" }, { "value": "3", "label": "KITAS" }, { "value": "5", "label": "Other" }],
			master_occupation_code: [{ "value": "1", "label": "occupation_code_1" }, { "value": "2", "label": "occupation_code_2" }, { "value": "3", "label": "occupation_code_3" }, { "value": "4", "label": "occupation_code_4" }],
			master_annual_income: [{ "value": "1", "label": "<50 Mil" }, { "value": "2", "label": "50-100 Mil" }, { "value": "3", "label": "100-200 Mil" }, { "value": "4", "label": ">200 Mil" }],
			master_source_income: [{ "value": "1", "label": "source_income_1" }, { "value": "2", "label": "source_income_2" }, { "value": "3", "label": "source_income_3" }, { "value": "4", "label": "source_income_4" }],
			bank: [
				{
					value: 'bca',
					label: 'BCA',
					code: 'CENAIDJA'
				},
				{
					value: 'cimb',
					label: 'CIMB NIAGA',
					code: ''
				},
				{
					value: 'permata',
					label: 'Permata Bank',
					code: ''
				},
				{
					value: 'bri',
					label: 'BRI',
					code: ''
				},
				// {
				// 	value:'mandiri',
				// 	label:'Mandiri - Bank Mandiri',
				// 	code:'BMRIIDJACUS'
				// },
				{
					value: 'other',
					label: 'Other',
					code: ''
				},
				// {
				// 	value:'cimb',
				// 	label:'CIMB - Bank CIMB Niaga',
				// 	code:'BNIAIDJA'
				// },
			],
			bank_list: [
				{ "value": "pt_bank_rakyat_indonesia_persero_tbk", "label": "PT. BANK RAKYAT INDONESIA PERSERO, Tbk" },
				{ "value": "pt_bank_mandiri_persero_tbk", "label": "PT. BANK MANDIRI PERSERO, Tbk" },
				{ "value": "pt_bank_negara_indonesia_persero_tbk", "label": "PT. BANK NEGARA INDONESIA PERSERO, Tbk" },
				{ "value": "pt_bank_tabungan_negara_persero_tbk", "label": "PT. BANK TABUNGAN NEGARA PERSERO, Tbk" },
				{ "value": "pt_bank_danamon_indonesia_tbk", "label": "PT. BANK DANAMON INDONESIA, Tbk" },
				{ "value": "pt_bank_permata_tbk", "label": "PT. BANK PERMATA, Tbk" },
				// { "value": "pt_bank_central_asia_tbk", "label": "PT. BANK CENTRAL ASIA, Tbk" },
				{ "value": "pt_bank_maybank_indonesia_tbk", "label": "PT. BANK MAYBANK  INDONESIA, Tbk" },
				{ "value": "pt_pan_indonesia_bank_tbk", "label": "PT. PAN INDONESIA BANK, Tbk" },
				// { "value": "pt_bank_cimb_niaga_tbk", "label": "PT. BANK CIMB NIAGA, Tbk" },
				{ "value": "pt_bank_uob_indonesia", "label": "PT. BANK UOB INDONESIA" },
				{ "value": "pt_bank_ocbc_nisp_tbk", "label": "PT. BANK OCBC NISP, Tbk" },
				{ "value": "pt_bank_artha_graha_internasional_tbk", "label": "PT. BANK ARTHA GRAHA INTERNASIONAL, Tbk" },
				{ "value": "pt_bank_bumi_arta_tbk", "label": "PT. BANK BUMI ARTA, Tbk" },
				{ "value": "pt_bank_hsbc_indonesia", "label": "PT BANK HSBC INDONESIA" },
				{ "value": "pt_bank_jtrust_indonesia_tbk", "label": "PT. BANK JTRUST INDONESIA, Tbk" },
				{ "value": "pt_bank_mayapada_international_tbk", "label": "PT. BANK MAYAPADA INTERNATIONAL, Tbk" },
				{ "value": "pt_bank_of_india_indonesia_tbk", "label": "PT. BANK OF INDIA INDONESIA, Tbk" },
				{ "value": "pt_bank_muamalat_indonesia_tbk", "label": "PT. BANK MUAMALAT INDONESIA, Tbk" },
				{ "value": "pt_bank_mestika_dharma_tbk", "label": "PT. BANK MESTIKA DHARMA, Tbk" },
				{ "value": "pt_bank_shinhan_indonesia", "label": "PT BANK SHINHAN INDONESIA" },
				{ "value": "pt_bank_sinarmas_tbk", "label": "PT. BANK SINARMAS, Tbk" },
				{ "value": "pt_bank_maspion_indonesia_tbk", "label": "PT. BANK MASPION INDONESIA, Tbk" },
				{ "value": "pt_bank_ganesha_tbk", "label": "PT. BANK GANESHA, Tbk" },
				{ "value": "pt_bank_icbc_indonesia", "label": "PT. BANK ICBC INDONESIA" },
				{ "value": "pt_bank_qnb_indonesia_tbk", "label": "PT. BANK QNB INDONESIA, Tbk" },
				{ "value": "pt_bank_woori_saudara_indonesia_1906_tbk", "label": "PT. BANK WOORI SAUDARA INDONESIA 1906, Tbk" },
				{ "value": "pt_bank_mega_tbk", "label": "PT. BANK MEGA, Tbk" },
				{ "value": "pt_bank_bni_syariah", "label": "PT. BANK BNI SYARIAH" },
				{ "value": "pt_bank_bukopin_tbk", "label": "PT. BANK BUKOPIN, Tbk" },
				{ "value": "pt_bank_syariah_mandiri", "label": "PT. BANK SYARIAH MANDIRI" },
				{ "value": "pt_bank_keb_hana_indonesia", "label": "PT. BANK KEB HANA INDONESIA" },
				{ "value": "pt_bank_mnc_internasional_tbk", "label": "PT BANK MNC INTERNASIONAL, Tbk" },
				{ "value": "pt_bank_rakyat_indonesia_agroniaga_tbk", "label": "PT. BANK RAKYAT INDONESIA AGRONIAGA, Tbk" },
				{ "value": "pt_bank_sbi_indonesia", "label": "PT. BANK SBI INDONESIA" },
				{ "value": "pt_bank_mega_syariah", "label": "PT. BANK MEGA SYARIAH" },
				{ "value": "pt_bank_index_selindo", "label": "PT. BANK INDEX SELINDO" },
				{ "value": "pt_bank_mayora", "label": "PT. BANK MAYORA" },
				{ "value": "pt_bank_china_construction_bank_indonesia_tbk", "label": "PT BANK CHINA CONSTRUCTION BANK INDONESIA, Tbk" },
				{ "value": "pt_bank_dbs_indonesia", "label": "PT. BANK DBS INDONESIA" },
				{ "value": "pt_bank_resona_perdania", "label": "PT. BANK RESONA PERDANIA" },
				{ "value": "pt_bank_mizuho_indonesia", "label": "PT. BANK MIZUHO INDONESIA" },
				{ "value": "pt_bank_capital_indonesia_tbk", "label": "PT. BANK CAPITAL INDONESIA, Tbk" },
				{ "value": "pt_bank_bnp_paribas_indonesia", "label": "PT. BANK BNP PARIBAS INDONESIA" },
				{ "value": "pt_bank_anz_indonesia", "label": "PT. BANK ANZ INDONESIA" },
				{ "value": "pt_bank_rabobank_international_indonesia", "label": "PT. BANK RABOBANK INTERNATIONAL INDONESIA" },
				{ "value": "pt_bank_ibk_indonesia_tbk", "label": "PT. BANK IBK INDONESIA, Tbk" },
				{ "value": "pt_bank_net_indonesia_syariah", "label": "PT. BANK NET INDONESIA SYARIAH" },
				{ "value": "pt_bank_ctbc_indonesia", "label": "PT. BANK CTBC INDONESIA" },
				{ "value": "pt_bank_commonwealth", "label": "PT. BANK COMMONWEALTH" },
				{ "value": "pt_bank_btpn_tbk", "label": "PT. BANK BTPN, Tbk" },
				{ "value": "pt_bank_victoria_syariah", "label": "PT. BANK VICTORIA SYARIAH" },
				{ "value": "pt_bank_brisyariah_tbk", "label": "PT. BANK BRISYARIAH, Tbk" },
				{ "value": "pt_bank_jabar_banten_syariah", "label": "PT. BANK JABAR BANTEN SYARIAH" },
				{ "value": "pt_bank_bisnis_internasional", "label": "PT. BANK BISNIS INTERNASIONAL" },
				{ "value": "pt_bank_jasa_jakarta", "label": "PT. BANK JASA JAKARTA" },
				{ "value": "pt_bank_yudha_bhakti_tbk", "label": "PT. BANK YUDHA BHAKTI, Tbk" },
				{ "value": "pt_bank_royal_indonesia", "label": "PT. BANK ROYAL INDONESIA" },
				{ "value": "pt_bank_nationalnobu_tbk", "label": "PT. BANK NATIONALNOBU, Tbk" },
				{ "value": "pt_bank_ina_perdana_tbk", "label": "PT. BANK INA PERDANA, Tbk" },
				{ "value": "pt_bank_panin_dubai_syariah_tbk", "label": "PT BANK PANIN DUBAI SYARIAH, Tbk" },
				{ "value": "pt_prima_master_bank", "label": "PT. PRIMA MASTER BANK" },
				{ "value": "pt_bank_syariah_bukopin", "label": "PT. BANK SYARIAH BUKOPIN" },
				{ "value": "pt_bank_sahabat_sampoerna", "label": "PT. BANK SAHABAT SAMPOERNA" },
				{ "value": "pt_bank_oke_indonesia_tbk", "label": "PT. BANK OKE INDONESIA, Tbk" },
				{ "value": "pt_bank_amar_indonesia", "label": "PT. BANK AMAR INDONESIA" },
				{ "value": "pt_bank_kesejahteraan_ekonomi", "label": "PT. BANK KESEJAHTERAAN EKONOMI" },
				{ "value": "pt_bank_bca_syariah", "label": "PT. BANK BCA SYARIAH" },
				{ "value": "pt_bank_artos_indonesia", "label": "PT. BANK ARTOS INDONESIA" },
				{ "value": "pt_bank_tabungan_pensiunan_nasional_syariah_tbk", "label": "PT. BANK TABUNGAN PENSIUNAN NASIONAL SYARIAH, Tbk" },
				{ "value": "pt_bank_multiarta_sentosa", "label": "PT. BANK MULTIARTA SENTOSA" },
				{ "value": "pt_bank_fama_internasional", "label": "PT. BANK FAMA INTERNASIONAL" },
				{ "value": "pt_bank_mandiri_taspen", "label": "PT. BANK MANDIRI TASPEN" },
				{ "value": "pt_bank_victoria_international_tbk", "label": "PT. BANK VICTORIA INTERNATIONAL, Tbk" },
				{ "value": "pt_bank_harda_internasional", "label": "PT. BANK HARDA INTERNASIONAL" },
				{ "value": "pt_bpd_jawa_barat_dan_banten_tbk", "label": "PT. BPD JAWA BARAT DAN BANTEN, Tbk" },
				{ "value": "pt_bpd_dki", "label": "PT. BPD DKI" },
				{ "value": "pt_bpd_daerah_istimewa_yogyakarta", "label": "PT. BPD DAERAH ISTIMEWA YOGYAKARTA" },
				{ "value": "pt_bpd_jawa_tengah", "label": "PT. BPD JAWA TENGAH" },
				{ "value": "pt_bpd_jawa_timur_tbk", "label": "PT. BPD JAWA TIMUR, Tbk" },
				{ "value": "pt_bpd_jambi", "label": "PT. BPD JAMBI" },
				{ "value": "pt_bank_aceh_syariah", "label": "PT. BANK ACEH SYARIAH" },
				{ "value": "pt_bpd_sumatera_utara", "label": "PT. BPD SUMATERA UTARA" },
				{ "value": "pt_bpd_sumatera_barat", "label": "PT. BPD SUMATERA BARAT" },
				{ "value": "pt_bpd_riau_kepri", "label": "PT. BPD RIAU KEPRI" },
				{ "value": "pt_bpd_sumatera_selatan_dan_bangka_belitung", "label": "PT. BPD SUMATERA SELATAN DAN BANGKA BELITUNG" },
				{ "value": "pt_bpd_lampung", "label": "PT. BPD LAMPUNG" },
				{ "value": "pt_bpd_kalimantan_selatan", "label": "PT. BPD KALIMANTAN SELATAN" },
				{ "value": "pt_bpd_kalimantan_barat", "label": "PT. BPD KALIMANTAN BARAT" },
				{ "value": "pt_bpd_kalimantan_timur_dan_kalimantan_utara", "label": "PT. BPD KALIMANTAN TIMUR DAN KALIMANTAN UTARA" },
				{ "value": "pt_bpd_kalimantan_tengah", "label": "PT. BPD KALIMANTAN TENGAH" },
				{ "value": "pt_bpd_sulawesi_selatan_dan_sulawesi_barat", "label": "PT. BPD SULAWESI SELATAN DAN SULAWESI BARAT" },
				{ "value": "pt_bpd_sulawesi_utara_dan_gorontalo", "label": "PT. BPD SULAWESI UTARA DAN  GORONTALO" },
				{ "value": "pt_bank_ntb_syariah", "label": "PT BANK NTB SYARIAH" },
				{ "value": "pt_bpd_bali", "label": "PT. BPD BALI" },
				{ "value": "pt_bpd_nusa_tenggara_timur", "label": "PT. BPD NUSA TENGGARA TIMUR" },
				{ "value": "pt_bpd_maluku_dan_maluku_utara", "label": "PT. BPD MALUKU DAN MALUKU UTARA" },
				{ "value": "pt_bpd_papua", "label": "PT. BPD PAPUA" },
				{ "value": "pt_bpd_bengkulu", "label": "PT. BPD BENGKULU" },
				{ "value": "pt_bpd_sulawesi_tengah", "label": "PT. BPD SULAWESI TENGAH" },
				{ "value": "pt_bpd_sulawesi_tenggara", "label": "PT. BPD SULAWESI TENGGARA" },
				{ "value": "pt_bpd_banten_tbk", "label": "PT BPD BANTEN, Tbk" }
			],
			rdn_bank: [
				{
					value: 'bca',
					label: 'BCA',
				},
				{
					value: 'cimb',
					label: 'CIMB NIAGA'
				},
				{
					value: 'permata',
					label: 'Permata Bank'
				},
				{
					value: 'mandiri',
					label: 'Mandiri - Bank Mandiri'
				},
				{
					value: 'bri',
					label: 'BRI'
				},
			],
			rdn_bank_option: [
				// {
				// 	value: 'bca',
				// 	label: 'BCA',
				// },
				{
					value: 'cimb',
					label: 'CIMB NIAGA'
				},
				{
					value: 'permata',
					label: 'Permata Bank'
				},
				// {
				// 	value: 'mandiri',
				// 	label: 'Mandiri - Bank Mandiri'
				// },
			],			
			religion: [
				{
					value: 'islam',
					label: 'Islam'
				},
				{
					value: 'katolik',
					label: 'Katolik'
				},
				{
					value: 'kristen',
					label: 'Kristen'
				},
				{
					value: 'hindu',
					label: 'Hindu'
				},
				{
					value: 'buddha',
					label: 'Buddha'
				},
				{
					value: 'other',
					label: 'Other'
				}
			]
		},

		optYoutube: {
			width: "100%",
			height: '250',
			// playerVars: {
			// https://developers.google.com/youtube/player_parameters
			// autoplay: 1,
			// },
		},
		redirectRegistration: false,
		redirectSubmited: false,
		redirectSummary: false,
		foreignerSubmited: false,
		setting_contact: [],
		setting_web: [],
		setting_general: [],
		welcome_title: {},
		privacy: {},
		legal: {},
		setting_liveness_limit:0,

		dimensions: {
			width: '',
			height: '',
		},
	}

	checkMaintenanceStatus() {
		async function getMaintenanceStatus() {
			try {
				let geoJs = await axios.get('https://get.geojs.io/v1/ip.json');

				let maintenanceModeValue = await axios.post(link_api + '/api/maintenance/status', {
					params: { ip_address: geoJs.data.ip }
				});

				return maintenanceModeValue.data.maintenance_mode;
			} catch (error) {
				return false;
			}
		}

		getMaintenanceStatus().then((resultSemongko) => {
			this.setState({ maintenance_mode: resultSemongko });
		})
	}

	async getMasterData() {
		let option_master_family_relationship_cimb = [];
		let option_master_nationality_ksei = [];
		let option_master_marital_status = [];
		let option_master_educational_background_ksei = [];
		let option_master_occupation_ksei = [];
		let option_master_occupation_bca = [];
		let option_master_income_per_annum_ksei = [];
		let option_master_source_of_fund = [];
		let option_master_investment_objectives = [];
		let option_master_city_ksei = [];
		let option_master_country = [];
		let option_master_province_ksei = [];
		let option_master_monthly_income_range = [];

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mnk' } }).then(result => {
			if (result.data.status) {
				option_master_nationality_ksei = result.data.data.map((value) => { return { value: value.id, label: value.nationality } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mms' } }).then(result => {
			if (result.data.status) {
				option_master_marital_status = result.data.data.map((value) => { return { value: value.id.toString(), label: value.marital_status.charAt(0).toUpperCase() + value.marital_status.slice(1) } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mebk' } }).then(result => {
			if (result.data.status) {
				option_master_educational_background_ksei = result.data.data.map((value) => { return { value: value.id, label: value.name } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mok' } }).then(result => {
			if (result.data.status) {
				option_master_occupation_ksei = result.data.data.map((value) => { return { value: value.id, label: value.ocupation_name } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mob' } }).then(result => {
			if (result.data.status) {
				option_master_occupation_bca = result.data.data.map((value) => { return { value: parseInt(value.code), label: value.name } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mipk' } }).then(result => {
			if (result.data.status) {
				option_master_income_per_annum_ksei = result.data.data.map((value) => { return { value: value.id, label: value.income_per_annum } })
			}
		}, (error) => {
			console.log(error);
		});

		// await axios.get(link_api + '/api/master-data', { params: { master_data: 'master_source_of_fund' } }).then(result => {
		// 	if (result.data.status) {
		// 		option_master_source_of_fund = result.data.data.map((value) => { return { value: value.id, label: value.source_of_fund } })
		// 	}
		// }, (error) => {
		// 	console.log(error);
		// });

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'msof' } }).then(result => {
			if (result.data.status) {
				option_master_source_of_fund = result.data.data.map((value) => { return { value: value.id, label: value.source_of_fund } })
			}
		}, (error) => {
			console.log(error);
		});		

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mio' } }).then(result => {
			if (result.data.status) {
				option_master_investment_objectives = result.data.data.map((value) => { return { value: value.id, label: value.investment_objectives } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mc' } }).then(result => {
			if (result.data.status) {
				option_master_country = result.data.data.map((value) => { return { value: value.id, label: value.country } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mpk' } }).then(result => {
			if (result.data.status) {
				option_master_province_ksei = result.data.data.map((value) => { return { value: value.province_code, label: value.province_name } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mck' } }).then(result => {
			if (result.data.status) {
				option_master_city_ksei = result.data.data.map((value) => { return { value: value.city_code, label: value.city_name, province_id: value.province_id } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mmirc' } }).then(result => {
			if (result.data.status) {
				option_master_monthly_income_range = result.data.data.map((value) => { return { value: value.id, label: value.name } })
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/master-data', { params: { master_data: 'mfrc' } }).then(result => {
			if (result.data.status) {
				option_master_family_relationship_cimb = result.data.data.map((value) => { return { value: value.id, label: value.name } })
			}
		}, (error) => {
			console.log(error);
		});		

		this.setState({
			master: {
				...this.state.master,
				master_nationality_ksei: option_master_nationality_ksei,
				master_marital_status: option_master_marital_status,
				master_educational_background_ksei: option_master_educational_background_ksei,
				master_occupation_ksei: option_master_occupation_ksei,
				master_occupation_bca: option_master_occupation_bca,
				master_income_per_annum_ksei: option_master_income_per_annum_ksei,
				master_source_of_fund: option_master_source_of_fund,
				master_investment_objectives: option_master_investment_objectives,
				master_country: option_master_country,
				master_province_ksei: option_master_province_ksei,
				master_city_ksei: option_master_city_ksei,
				master_monthly_income_range: option_master_monthly_income_range,
				master_family_relationship_cimb: option_master_family_relationship_cimb
			}
		});
	}

	dispatch = (action) => {
		if (action.type === 'handleSubmit') {
			if (action.field === 'citizenship') {
				let ref_id = this.state.ref_id ? this.state.ref_id : null;
				let user_id = this.state.user_id ? this.state.user_id : '';
				this.setState({
					citizenship: {
						...this.state.citizenship,
						identitynumber: action.values.identitynumber,
						fullname: action.values.fullname,
						dob: action.values.dob,
						foreigner: action.values.foreigner,
						otp: action.values.otp,
						email: action.values.email,
						phonenumber: action.values.phonenumber,
					},
					ref_id: ref_id === null ? null : ref_id,
					// user_id: ref_id === null ? action.values.referral_code : null,
					user_id: ref_id === null ? action.values.referral_code : user_id,
					process: true,
				}, () => {
					let fields = { ...this.state.citizenship };
					let errors = { ...this.state.errors };

					if (!fields['foreigner']) {
						// CEK DUKCAPIL
						// const fullname = fields['fullname'].split(' ');
						const param = {
							nik: fields['identitynumber'],
							// first_name: fullname[0],
							// last_name: fullname[1] ? fullname[1] : '',
							full_name: fields['fullname'],
							dob: fields['dob'],
							ref_id: this.state.ref_id !== '' ? this.state.ref_id : '',
							user_id: this.state.user_id !== '' ? this.state.user_id : '',
							ip_address: this.state.ip_address,
							user_agent: this.state.user_agent,
							referral_url: this.state.referral_url,
						}

						axios.post(link_api + '/api/dukcapil-validation', queryString.stringify(param), headers).then(result => {
							this.setState({
								process: false,
							});
							if (result.data.status) {
								// console.log(result.data);
								// console.log(search(result.data.data.existing_member.rdn_bank, this.state.master.bank_list))
								if (result.data.data.existing_member.submitted === 1) {
									// console.log(result.data.data.existing_member)
									errors['global_type'] = 'success';
									errors['global_message'] = 'already_sent';
									this.setState({
										errors: errors,
										submitted: {
											submitted: result.data.data.existing_member.submitted,
											identitynumber: (!fields['foreigner']) ? result.data.data.existing_member.ktp_number : result.data.data.existing_member.kitas,
											fullname: result.data.data.existing_member.first_name + ' ' + result.data.data.existing_member.last_name,
											dob: result.data.data.existing_member.date_of_birth,
											ksei_status: result.data.data.existing_member.ksei_status,
											ksei_sre: result.data.data.existing_member.ksei_sre,
											ksei_sid: result.data.data.existing_member.ksei_sid,
											rdn_status: result.data.data.existing_member.rdn_status,
											rdn_bank: result.data.data.existing_member.rdn_bank,
											rdn_number: result.data.data.existing_member.rdn_number,
											rdn_pdf_form_path: result.data.data.existing_member.rdn_pdf_form_path,
											form_application_doclink: result.data.data.existing_member.form_application_doclink,
											application_form_pdf_path: result.data.data.existing_member.application_form_pdf_path,
											term_service_doclink: result.data.data.existing_member.term_service_doclink,
											term_service_pdf_path: result.data.data.existing_member.term_service_pdf_path,
										}
									})
								} else {
									let _npwpReasonResult = result.data.data.existing_member.no_npwp_reason;
									let npwpReasonResult = ( _npwpReasonResult !== "" && _npwpReasonResult !== null && !isNaN(_npwpReasonResult)) ? _npwpReasonResult.toString() : _npwpReasonResult;
									let npwpReasonStateVal = "";
									if(npwpReasonResult !== null && npwpReasonResult !== undefined && npwpReasonResult !== "") {
										npwpReasonStateVal = search(npwpReasonResult, this.state.master.master_no_npwp_reason);
									} else {
										npwpReasonStateVal = "";
									}

									// Monthly Income Range
									let monthlyIncomeRangeStateVal = "";
									
									try {
										let _monthlyIncomeRangeResult = result.data.data.existing_member.monthly_income_range;
										if(_monthlyIncomeRangeResult) {
											if(isNaN(_monthlyIncomeRangeResult)) {
												_monthlyIncomeRangeResult = parseInt(_monthlyIncomeRangeResult);
											}

											monthlyIncomeRangeStateVal = search(_monthlyIncomeRangeResult, this.state.master.master_monthly_income_range);
										}
									} catch(e) {
										console.log(e);
									}

									// Work Year and Month
									let workYearStateVal = result.data.data.existing_member.work_year ? result.data.data.existing_member.work_year : "";
									let workMonthStateVal = result.data.data.existing_member.work_month ? result.data.data.existing_member.work_month : "";

									// pos_id_type
									let posIdTypeStateVal = "";
									let posIdTypeResult = result.data.data.existing_member.pos_id_type ? result.data.data.existing_member.pos_id_type : "";
									if(posIdTypeResult) {
										posIdTypeStateVal = search(posIdTypeResult.toString(), this.state.master.master_id_type);
									}

									// pos_occupation_code
									let posOccupationStateVal = "";
									let posOccupationResult = result.data.data.existing_member.pos_occupation_code ? result.data.data.existing_member.pos_occupation_code : "";
									if(posOccupationResult) {
										posOccupationStateVal = search(posOccupationResult.toString(), this.state.master.master_occupation_code);
									}

									// pos_annual_income
									let posAnnualIncomeStateVal = "";
									let posAnnualIncomeResult = result.data.data.existing_member.pos_annual_income;
									if(posAnnualIncomeResult) {
										posAnnualIncomeStateVal = search(posAnnualIncomeResult.toString(), this.state.master.master_annual_income);
									}

									// pos_source_of_income
									let posSoiStateVal = "";
									let posSoiResult = result.data.data.existing_member.pos_source_of_income;
									if(posSoiResult) {
										posSoiStateVal = search(posSoiResult.toString(), this.state.master.master_source_income);
									}

									// Pos Gender
									let posGenderStateVal = "";
									let posGenderResult = result.data.data.existing_member.pos_gender;
									if(posGenderResult) {
										posGenderStateVal = search(posGenderResult, this.state.master.sex);
									}

									// pos_marital_status
									let posMaritalStatusStateVal = "";
									let posMaritalStatusResult = result.data.data.existing_member.pos_marital_status ? result.data.data.existing_member.pos_marital_status : "";
									if(posMaritalStatusResult) {
										posMaritalStatusStateVal = search(posMaritalStatusResult, this.state.master.master_marital_status_cimb);
									}

									// pos_family_relationship
									let posFamilyRelationshipStateVal = "";
									let posFamilyRelationshipResult = result.data.data.existing_member.pos_family_relationship ? result.data.data.existing_member.pos_family_relationship : "";
									if(posFamilyRelationshipResult) {
										posFamilyRelationshipStateVal = search(posFamilyRelationshipResult, this.state.master.master_family_relationship_cimb);
									}

									// pos_email_address
									let posEmailAddressStateVal = "";
									let posEmailAddressResult = result.data.data.existing_member.pos_email_address ? result.data.data.existing_member.pos_email_address : "";
									if(posEmailAddressResult) {
										posEmailAddressStateVal = posEmailAddressResult;
									}

									// pos_district
									let posDistrictStateVal = "";
									let posDistrictResult = result.data.data.existing_member.pos_district ? result.data.data.existing_member.pos_district : "";
									if(posDistrictResult) {
										posDistrictStateVal = posDistrictResult;
									}

									// pos_subdistrict
									let posSubDistrictStateVal = "";
									let posSubDistrictResult = result.data.data.existing_member.pos_subdistrict ? result.data.data.existing_member.pos_subdistrict : "";
									if(posSubDistrictResult) {
										posSubDistrictStateVal = posSubDistrictResult;
									}

									// pos_rt
									let posRtStateVal = "";
									let posRtResult = result.data.data.existing_member.pos_rt ? result.data.data.existing_member.pos_rt : "";
									if(posRtResult) {
										posRtStateVal = posRtResult;
									}
									
									// pos_rw
									let posRwStateVal = "";
									let posRwResult = result.data.data.existing_member.pos_rw ? result.data.data.existing_member.pos_rw : "";
									if(posRwResult) {
										posRwStateVal = posRwResult;
									}											
									
									// pos_no_npwp_reason
									let posNoNpwpReasonStateVal = "";
									let posNoNpwpReasonResult = result.data.data.existing_member.pos_no_npwp_reason ? result.data.data.existing_member.pos_no_npwp_reason : "";
									if(posNoNpwpReasonResult) {
										posNoNpwpReasonStateVal = search(posNoNpwpReasonResult.toString(), this.state.master.master_no_npwp_reason);
									}

									const bank_account = result.data.data.existing_member.bank_account ? result.data.data.existing_member.bank_account : '';
									this.setState({
										member_id: result.data.data.existing_member.id,
										livenessSubmitCount: result.data.data.existing_member.liveness_submit_count,
										registration: {
											...this.state.registration,
											auth_token: result.data.data.existing_member.auth_token,
											first_name: result.data.data.existing_member.first_name,
											middle_name: result.data.data.existing_member.middle_name,
											last_name: result.data.data.existing_member.last_name,
											// nationality: search(result.data.data.existing_member.nationality, this.state.master.master_nationality_ksei),
											nationality: 104,
											place_of_birth: result.data.data.existing_member.place_of_birth,
											date_of_birth: result.data.data.existing_member.date_of_birth,
											sex: result.data.data.existing_member.sex ? result.data.data.existing_member.sex : this.state.registration.sex,
											religion: result.data.data.existing_member.religion ? this.state.master.religion.filter(index => index.value === result.data.data.existing_member.religion).length !== 0 ? result.data.data.existing_member.religion : 'other' : this.state.registration.religion,
											religion_other: this.state.master.religion.filter(index => index.value === result.data.data.existing_member.religion).length === 0 ? result.data.data.existing_member.religion : '',
											marital_status: result.data.data.existing_member.marital_status ? result.data.data.existing_member.marital_status : this.state.registration.marital_status,
											spouse_name: result.data.data.existing_member.spouse_name !== null ? result.data.data.existing_member.spouse_name : '',
											mother_maiden_name: result.data.data.existing_member.mother_maiden_name !== null ? result.data.data.existing_member.mother_maiden_name : '',
											heir_name: result.data.data.existing_member.heir_name,
											heir_relation: result.data.data.existing_member.heir_relation,
											educational_background: search(result.data.data.existing_member.educational_background, this.state.master.master_educational_background_ksei) ? search(result.data.data.existing_member.educational_background, this.state.master.master_educational_background_ksei) : '',
											income_per_annum: search(result.data.data.existing_member.income_per_annum, this.state.master.master_income_per_annum_ksei) ? search(result.data.data.existing_member.income_per_annum, this.state.master.master_income_per_annum_ksei) : '',
											source_of_fund: search(parseInt(result.data.data.existing_member.source_of_fund), this.state.master.master_source_of_fund) ? search(parseInt(result.data.data.existing_member.source_of_fund), this.state.master.master_source_of_fund) : '',
											source_of_fund_others: result.data.data.existing_member.source_of_fund_others !== null ? result.data.data.existing_member.source_of_fund_others : '',
											investment_objectives: search(parseInt(result.data.data.existing_member.investment_objectives), this.state.master.master_investment_objectives),
											asset_owner: result.data.data.existing_member.asset_owner !== null && result.data.data.existing_member.asset_owner.toString() ? result.data.data.existing_member.asset_owner.toString() : this.state.registration.asset_owner.toString(),

											occupation: search(parseInt(result.data.data.existing_member.occupation), this.state.master.master_occupation_bca),
											// occupation_others: result.data.data.existing_member.occupation_others,
											// nature_of_business: result.data.data.existing_member.nature_of_business,
											job_position: result.data.data.existing_member.job_position !== null ? result.data.data.existing_member.job_position : '',
											field_of_business: result.data.data.existing_member.field_of_business !== null ? result.data.data.existing_member.field_of_business : '',
											monthly_income_range: monthlyIncomeRangeStateVal,
											company_name: result.data.data.existing_member.company_name !== null ? result.data.data.existing_member.company_name : '',
											company_address: result.data.data.existing_member.company_address !== null ? result.data.data.existing_member.company_address : '',
											company_country: search(parseInt(result.data.data.existing_member.company_country), this.state.master.master_country),
											company_province: search(parseInt(result.data.data.existing_member.company_province), this.state.master.master_province_ksei),
											company_city: search(parseInt(result.data.data.existing_member.company_city), this.state.master.master_city_ksei),
											company_postal_code: result.data.data.existing_member.company_postal_code !== null ? result.data.data.existing_member.company_postal_code : '',
											company_phone_area_code: result.data.data.existing_member.company_phone_area_code !== null ? result.data.data.existing_member.company_phone_area_code : '',
											company_phone_number: result.data.data.existing_member.company_phone_number !== null ? result.data.data.existing_member.company_phone_number : '',

											company_rt: result.data.data.existing_member.company_rt ? result.data.data.existing_member.company_rt : "",
											company_rw: result.data.data.existing_member.company_rw ? result.data.data.existing_member.company_rw : "",
											company_subdistrict: result.data.data.existing_member.company_subdistrict ? result.data.data.existing_member.company_subdistrict : "",
											company_district: result.data.data.existing_member.company_district ? result.data.data.existing_member.company_district : "",

											company_district_master: result.data.data.existing_member.company_district_master ? result.data.data.existing_member.company_district_master : "",
											company_subdistrict_master: result.data.data.existing_member.company_subdistrict_master ? result.data.data.existing_member.company_subdistrict_master : "",

											work_year: workYearStateVal,
											work_month: workMonthStateVal,

											pos_full_name: result.data.data.existing_member.pos_full_name !== null ? result.data.data.existing_member.pos_full_name : '',
											pos_place_of_birth: result.data.data.existing_member.pos_place_of_birth !== null ? result.data.data.existing_member.pos_place_of_birth : '',
											pos_dob: result.data.data.existing_member.pos_dob,
											pos_home_phone: result.data.data.existing_member.pos_home_phone !== null ? result.data.data.existing_member.pos_home_phone : '',
											pos_mobile_phone: result.data.data.existing_member.pos_mobile_phone !== null ? result.data.data.existing_member.pos_mobile_phone : '',
											pos_address: result.data.data.existing_member.pos_address !== null ? result.data.data.existing_member.pos_address : '',
											pos_province: search(parseInt(result.data.data.existing_member.pos_province), this.state.master.master_province_ksei),
											pos_city: search(parseInt(result.data.data.existing_member.pos_city), this.state.master.master_city_ksei),
											pos_postalcode: result.data.data.existing_member.pos_postalcode !== null ? result.data.data.existing_member.pos_postalcode : '',

											pos_id_type: posIdTypeStateVal,

											pos_idcard_no: result.data.data.existing_member.pos_idcard_no !== null ? result.data.data.existing_member.pos_idcard_no : '',
											pos_idcard_expdate: result.data.data.existing_member.pos_idcard_expdate,
											pos_occupation_code: posOccupationStateVal,

											pos_occupation_name: result.data.data.existing_member.pos_occupation_name !== null ? result.data.data.existing_member.pos_occupation_name : '',
											pos_company_name: result.data.data.existing_member.pos_company_name !== null ? result.data.data.existing_member.pos_company_name : '',
											pos_company_address: result.data.data.existing_member.pos_company_address !== null ? result.data.data.existing_member.pos_company_address : '',
											pos_company_province: search(parseInt(result.data.data.existing_member.pos_company_province), this.state.master.master_province_ksei),
											pos_company_city: search(parseInt(result.data.data.existing_member.pos_company_city), this.state.master.master_city_ksei),
											pos_company_postalcode: result.data.data.existing_member.pos_company_postalcode !== null ? result.data.data.existing_member.pos_company_postalcode : '',
											pos_company_phone_number: result.data.data.existing_member.pos_company_phone_number !== null ? result.data.data.existing_member.pos_company_phone_number : '',
											pos_company_fax: result.data.data.existing_member.pos_company_fax !== null ? result.data.data.existing_member.pos_company_fax : '',

											pos_annual_income:  posAnnualIncomeStateVal,
											pos_source_of_income: posSoiStateVal,

											pos_gender: posGenderStateVal,
											pos_marital_status: posMaritalStatusStateVal,
											pos_family_relationship: posFamilyRelationshipStateVal,
											pos_email_address: posEmailAddressStateVal,
											pos_rt: posRtStateVal,
											pos_rw: posRwStateVal,
											pos_district: posDistrictStateVal,
											pos_subdistrict: posSubDistrictStateVal,

											pos_district_master: result.data.data.existing_member.pos_district_master ? result.data.data.existing_member.pos_district_master : "",
											pos_subdistrict_master: result.data.data.existing_member.pos_subdistrict_master ? result.data.data.existing_member.pos_subdistrict_master : "",											

											pos_npwp_number: result.data.data.existing_member.pos_npwp_number ? result.data.data.existing_member.pos_npwp_number : "",
											pos_no_npwp_reason: posNoNpwpReasonStateVal,

											pos_ktp_path: result.data.data.existing_member.pos_ktp_path !== null ? result.data.data.existing_member.pos_ktp_path : '',
											pos_npwp_path: result.data.data.existing_member.pos_npwp_path !== null ? result.data.data.existing_member.pos_npwp_path : '',

											ktp_number: result.data.data.existing_member.ktp_number,
											ktp_path: result.data.data.existing_member.ktp_path !== null ? result.data.data.existing_member.ktp_path : '',
											ktp_expired_date: result.data.data.existing_member.ktp_expired_date,
											npwp_number: result.data.data.existing_member.npwp_number !== null ? result.data.data.existing_member.npwp_number : '',
											// no_npwp_reason: search(npwpReasonResult, this.state.master.master_no_npwp_reason),
											no_npwp_reason: npwpReasonStateVal,
											npwp_path: result.data.data.existing_member.npwp_path,
											npwp_registration_date: result.data.data.existing_member.npwp_registration_date !== '0001-01-01' ? result.data.data.existing_member.npwp_registration_date : this.state.registration.npwp_registration_date,
											passport_number: result.data.data.existing_member.passport_number !== null ? result.data.data.existing_member.passport_number : '',
											passport_path: result.data.data.existing_member.passport_file_path,
											passport_expired_date: result.data.data.existing_member.passport_expired_date !== '0001-01-01' ? result.data.data.existing_member.passport_expired_date : this.state.registration.passport_expired_date,
											kitas: result.data.data.existing_member.kitas,
											kitas_path: result.data.data.existing_member.kitas_path !== null ? result.data.data.existing_member.kitas_path : '',
											kitas_expired_date: result.data.data.existing_member.kitas_expired_date !== '0001-01-01' ? result.data.data.existing_member.kitas_expired_date : this.state.registration.kitas_expired_date,
											self_photo_path: result.data.data.existing_member.self_photo_path !== null ? result.data.data.existing_member.self_photo_path : '',

											address_1: result.data.data.existing_member.address_1 !== null ? result.data.data.existing_member.address_1 : '',
											address_2: result.data.data.existing_member.address_2,
											address_3: result.data.data.existing_member.address_3,
											country: search(parseInt(result.data.data.existing_member.country), this.state.master.master_country),
											province: search(parseInt(result.data.data.existing_member.province), this.state.master.master_province_ksei),
											city: search(parseInt(result.data.data.existing_member.city), this.state.master.master_city_ksei),
											district: result.data.data.existing_member.district !== null ? result.data.data.existing_member.district : '',
											subdistrict: result.data.data.existing_member.subdistrict !== null ? result.data.data.existing_member.subdistrict : '',
											district_master: result.data.data.existing_member.district_master,
											subdistrict_master: result.data.data.existing_member.subdistrict_master,
											rt: result.data.data.existing_member.rt !== null ? result.data.data.existing_member.rt : '',
											rw: result.data.data.existing_member.rw !== null ? result.data.data.existing_member.rw : '',
											postal_code: result.data.data.existing_member.postal_code !== null ? result.data.data.existing_member.postal_code : '',
											home_phone: result.data.data.existing_member.home_phone !== null ? result.data.data.existing_member.home_phone : '',
											home_phone_area_code: result.data.data.existing_member.home_phone_area_code !== null ? result.data.data.existing_member.home_phone_area_code : '',
											mobile_phone: result.data.data.existing_member.mobile_phone !== null ? result.data.data.existing_member.mobile_phone : '',
											mobile_phone_country_code: result.data.data.existing_member.mobile_phone_country_code !== null ? result.data.data.existing_member.mobile_phone_country_code : '',
											fax_number: result.data.data.existing_member.fax_number,
											email_address: result.data.data.existing_member.email_address !== null ? result.data.data.existing_member.email_address : '',
											hrel_name: result.data.data.existing_member.hrel_name !== null ? result.data.data.existing_member.hrel_name : '',
											hrel_address: result.data.data.existing_member.hrel_address !== null ? result.data.data.existing_member.hrel_address : '',
											hrel_telp: result.data.data.existing_member.hrel_telp !== null ? result.data.data.existing_member.hrel_telp : '',
											hrel_telp_country_code: result.data.data.existing_member.hrel_telp_country_code !== null ? result.data.data.existing_member.hrel_telp_country_code : '',
											hrell_type: result.data.data.existing_member.hrell_type !== null ? result.data.data.existing_member.hrell_type : '',

											bank_name: bank_account ? (bank_account.bank_name === 'bca' || bank_account.bank_name === 'cimb' || bank_account.bank_name === 'permata' || bank_account.bank_name === 'bri') ? search(bank_account.bank_name, this.state.master.bank) : search('other', this.state.master.bank) : '',
											bank_name_others: bank_account ? (bank_account.bank_name !== 'bca' || bank_account.bank_name !== 'cimb' || bank_account.bank_name !== 'bri') ? search(bank_account.bank_name, this.state.master.bank_list) : '' : '',
											rdn_bank: result.data.data.existing_member ? search(result.data.data.existing_member.rdn_bank, this.state.master.rdn_bank) : '',
											bank_book_path: result.data.data.existing_member.bank_book_path !== null ? result.data.data.existing_member.bank_book_path : '',
											account_number: bank_account ? bank_account.account_number : '',
											bank_bic_code: bank_account && bank_account.bank_bic_code !== null ? bank_account.bank_bic_code : '',
											account_holder_name: bank_account && bank_account.account_holder_name ? bank_account.account_holder_name : '',
											account_currency: bank_account ? bank_account.account_currency : '',
											signature_path: result.data.data.existing_member.signature_path !== null ? result.data.data.existing_member.signature_path : '',
										},
										redirectRegistration: true,
										errors: {
											global_type: '',
											global_message: '',
										},
									})
								}
							} else {
								errors['global_type'] = 'danger';
								errors['global_message'] = result.data.message;
								this.setState({
									errors: errors,
									maintenance_mode: result.data.maintenance_mode,
								});
							}
						}, (error) => {
							console.log('/api/dukcapil-validation');
							console.log(error);
							errors['global_type'] = 'danger';
							errors['global_message'] = 'Cannot communicate with server.';
							this.setState({
								process: false,
								errors: errors
							});
						});
					} else {
						// CEK OTP
						const param = {
							params: {
								otp: fields['otp'],
								full_name: fields['fullname'],
								dob: fields['dob'],
								kitas: fields['identitynumber'],
								ref_id: this.state.ref_id !== '' ? this.state.ref_id : '',
								ip_address: this.state.ip_address,
								user_agent: this.state.user_agent,
								referral_url: this.state.referral_url,
							}
						}
						if (fields['otp'] !== '') {
							axios.get(link_api + '/api/lead', param, headers).then(result => {
								this.setState({
									process: false,
								});
								if (result.data.status) {
									// console.log(result.data.data.existing_member);
									if (result.data.data.existing_member.submitted === 1) {
										errors['global_type'] = 'success';
										errors['global_message'] = 'already_sent';
										this.setState({
											errors: errors
										})
										this.setState({
											errors: errors,
											submitted: {
												submitted: result.data.data.existing_member.submitted,
												identitynumber: (!fields['foreigner']) ? result.data.data.existing_member.ktp_number : result.data.data.existing_member.kitas,
												fullname: result.data.data.existing_member.first_name + ' ' + result.data.data.existing_member.last_name,
												dob: result.data.data.existing_member.date_of_birth,
												ksei_status: result.data.data.existing_member.ksei_status,
												ksei_sre: result.data.data.existing_member.ksei_sre,
												ksei_sid: result.data.data.existing_member.ksei_sid,
												rdn_status: result.data.data.existing_member.rdn_status,
												rdn_bank: result.data.data.existing_member.rdn_bank,
												rdn_number: result.data.data.existing_member.rdn_number,
												rdn_pdf_form_path: result.data.data.existing_member.rdn_pdf_form_path,
												form_application_doclink: result.data.data.existing_member.form_application_doclink,
												application_form_pdf_path: result.data.data.existing_member.application_form_pdf_path,
												term_service_doclink: result.data.data.existing_member.term_service_doclink,
												term_service_pdf_path: result.data.data.existing_member.term_service_pdf_path,
											}
										})
									} else {
										const bank_account = result.data.data.existing_member.bank_account ? result.data.data.existing_member.bank_account : '';
										this.setState({
											member_id: result.data.data.existing_member.id,
											registration: {
												...this.state.registration,
												auth_token: result.data.data.existing_member.auth_token,
												first_name: result.data.data.existing_member.first_name,
												middle_name: result.data.data.existing_member.middle_name,
												last_name: result.data.data.existing_member.last_name,
												nationality: search(result.data.data.existing_member.nationality, this.state.master.master_nationality_ksei),
												place_of_birth: result.data.data.existing_member.place_of_birth,
												date_of_birth: result.data.data.existing_member.date_of_birth,
												sex: result.data.data.existing_member.sex ? result.data.data.existing_member.sex : this.state.registration.sex,
												religion: result.data.data.existing_member.religion ? result.data.data.existing_member.religion : this.state.registration.religion,
												marital_status: result.data.data.existing_member.marital_status ? result.data.data.existing_member.marital_status : this.state.registration.marital_status,
												spouse_name: result.data.data.existing_member.spouse_name !== null ? result.data.data.existing_member.spouse_name : '',
												mother_maiden_name: result.data.data.existing_member.mother_maiden_name !== null ? result.data.data.existing_member.mother_maiden_name : '',
												heir_name: result.data.data.existing_member.heir_name,
												heir_relation: result.data.data.existing_member.heir_relation,
												educational_background: search(result.data.data.existing_member.educational_background, this.state.master.master_educational_background_ksei),
												income_per_annum: search(result.data.data.existing_member.income_per_annum, this.state.master.master_income_per_annum_ksei),
												source_of_fund: search(parseInt(result.data.data.existing_member.source_of_fund), this.state.master.master_source_of_fund),
												source_of_fund_others: result.data.data.existing_member.source_of_fund_others !== null ? result.data.data.existing_member.source_of_fund_others : '',
												investment_objectives: search(parseInt(result.data.data.existing_member.investment_objectives), this.state.master.master_investment_objectives),
												asset_owner: result.data.data.existing_member.asset_owner !== null && result.data.data.existing_member.asset_owner.toString() ? result.data.data.existing_member.asset_owner.toString() : this.state.registration.asset_owner.toString(),

												occupation: search(parseInt(result.data.data.existing_member.occupation), this.state.master.master_occupation_bca),
												// occupation_others: result.data.data.existing_member.occupation_others,
												// nature_of_business: result.data.data.existing_member.nature_of_business,
												job_position: result.data.data.existing_member.job_position !== null ? result.data.data.existing_member.job_position : '',
												field_of_business: result.data.data.existing_member.field_of_business !== null ? result.data.data.existing_member.field_of_business : '',
												company_name: result.data.data.existing_member.company_name !== null ? result.data.data.existing_member.company_name : '',
												company_address: result.data.data.existing_member.company_address !== null ? result.data.data.existing_member.company_address : '',
												company_country: search(parseInt(result.data.data.existing_member.company_country), this.state.master.master_country),
												company_province: search(parseInt(result.data.data.existing_member.company_province), this.state.master.master_province_ksei),
												company_city: search(parseInt(result.data.data.existing_member.company_city), this.state.master.master_city_ksei),
												company_postal_code: result.data.data.existing_member.company_postal_code !== null ? result.data.data.existing_member.company_postal_code : '',
												company_phone_area_code: result.data.data.existing_member.company_phone_area_code !== null ? result.data.data.existing_member.company_phone_area_code : '',
												company_phone_number: result.data.data.existing_member.company_phone_number !== null ? result.data.data.existing_member.company_phone_number : '',

												pos_full_name: result.data.data.existing_member.pos_full_name !== null ? result.data.data.existing_member.pos_full_name : '',
												pos_place_of_birth: result.data.data.existing_member.pos_place_of_birth !== null ? result.data.data.existing_member.pos_place_of_birth : '',
												pos_dob: result.data.data.existing_member.pos_dob,
												pos_home_phone: result.data.data.existing_member.pos_home_phone !== null ? result.data.data.existing_member.pos_home_phone : '',
												pos_mobile_phone: result.data.data.existing_member.pos_mobile_phone !== null ? result.data.data.existing_member.pos_mobile_phone : '',
												pos_address: result.data.data.existing_member.pos_address !== null ? result.data.data.existing_member.pos_address : '',
												pos_province: search(parseInt(result.data.data.existing_member.pos_province), this.state.master.master_province_ksei),
												pos_city: search(parseInt(result.data.data.existing_member.pos_city), this.state.master.master_city_ksei),
												pos_postalcode: result.data.data.existing_member.pos_postalcode !== null ? result.data.data.existing_member.pos_postalcode : '',
												pos_id_type: search(parseInt(result.data.data.existing_member.pos_id_type), this.state.master.master_id_type),
												pos_idcard_no: result.data.data.existing_member.pos_idcard_no !== null ? result.data.data.existing_member.pos_idcard_no : '',
												pos_idcard_expdate: result.data.data.existing_member.pos_idcard_expdate,
												pos_occupation_code: search(parseInt(result.data.data.existing_member.pos_occupation_code), this.state.master.master_occupation_code),
												pos_occupation_name: result.data.data.existing_member.pos_occupation_name !== null ? result.data.data.existing_member.pos_occupation_name : '',
												pos_company_name: result.data.data.existing_member.pos_company_name !== null ? result.data.data.existing_member.pos_company_name : '',
												pos_company_address: result.data.data.existing_member.pos_company_address !== null ? result.data.data.existing_member.pos_company_address : '',
												pos_company_province: search(parseInt(result.data.data.existing_member.pos_company_province), this.state.master.master_province_ksei),
												pos_company_city: search(parseInt(result.data.data.existing_member.pos_company_city), this.state.master.master_city_ksei),
												pos_company_postalcode: result.data.data.existing_member.pos_company_postalcode !== null ? result.data.data.existing_member.pos_company_postalcode : '',
												pos_company_phone_number: result.data.data.existing_member.pos_company_phone_number !== null ? result.data.data.existing_member.pos_company_phone_number : '',
												pos_company_fax: result.data.data.existing_member.pos_company_fax !== null ? result.data.data.existing_member.pos_company_fax : '',
												pos_annual_income: search(parseInt(result.data.data.existing_member.pos_annual_income), this.state.master.master_annual_income),
												pos_source_of_income: search(parseInt(result.data.data.existing_member.pos_source_of_income), this.state.master.master_source_income),

												ktp_number: result.data.data.existing_member.ktp_number,
												ktp_path: result.data.data.existing_member.ktp_path !== null ? result.data.data.existing_member.ktp_path : '',
												ktp_expired_date: result.data.data.existing_member.ktp_expired_date,
												npwp_number: result.data.data.existing_member.npwp_number !== null ? result.data.data.existing_member.npwp_number : '',
												npwp_path: result.data.data.existing_member.npwp_path,
												npwp_registration_date: result.data.data.existing_member.npwp_registration_date !== '0001-01-01' ? result.data.data.existing_member.npwp_registration_date : this.state.registration.npwp_registration_date,
												passport_number: result.data.data.existing_member.passport_number !== null ? result.data.data.existing_member.passport_number : '',
												passport_path: result.data.data.existing_member.passport_file_path,
												passport_expired_date: result.data.data.existing_member.passport_expired_date !== '0001-01-01' ? result.data.data.existing_member.passport_expired_date : this.state.registration.passport_expired_date,
												kitas: result.data.data.existing_member.kitas,
												kitas_path: result.data.data.existing_member.kitas_path !== null ? result.data.data.existing_member.kitas_path : '',
												kitas_expired_date: result.data.data.existing_member.kitas_expired_date !== '0001-01-01' ? result.data.data.existing_member.kitas_expired_date : this.state.registration.kitas_expired_date,
												self_photo_path: result.data.data.existing_member.self_photo_path !== null ? result.data.data.existing_member.self_photo_path : '',

												address_1: result.data.data.existing_member.address_1 !== null ? result.data.data.existing_member.address_1 : '',
												address_2: result.data.data.existing_member.address_2,
												address_3: result.data.data.existing_member.address_3,
												country: search(parseInt(result.data.data.existing_member.country), this.state.master.master_country),
												province: search(parseInt(result.data.data.existing_member.province), this.state.master.master_province_ksei),
												city: search(parseInt(result.data.data.existing_member.city), this.state.master.master_city_ksei),
												district: result.data.data.existing_member.district !== null ? result.data.data.existing_member.district : '',
												subdistrict: result.data.data.existing_member.subdistrict !== null ? result.data.data.existing_member.subdistrict : '',
												rt: result.data.data.existing_member.rt !== null ? result.data.data.existing_member.rt : '',
												rw: result.data.data.existing_member.rw !== null ? result.data.data.existing_member.rw : '',
												postal_code: result.data.data.existing_member.postal_code !== null ? result.data.data.existing_member.postal_code : '',
												home_phone: result.data.data.existing_member.home_phone !== null ? result.data.data.existing_member.home_phone : '',
												home_phone_area_code: result.data.data.existing_member.home_phone_area_code !== null ? result.data.data.existing_member.home_phone_area_code : '',
												mobile_phone: result.data.data.existing_member.mobile_phone !== null ? result.data.data.existing_member.mobile_phone : '',
												mobile_phone_country_code: result.data.data.existing_member.mobile_phone_country_code !== null ? result.data.data.existing_member.mobile_phone_country_code : '',
												fax_number: result.data.data.existing_member.fax_number,
												email_address: result.data.data.existing_member.email_address !== null ? result.data.data.existing_member.email_address : '',
												hrel_name: result.data.data.existing_member.hrel_name !== null ? result.data.data.existing_member.hrel_name : '',
												hrel_address: result.data.data.existing_member.hrel_address !== null ? result.data.data.existing_member.hrel_address : '',
												hrel_telp: result.data.data.existing_member.hrel_telp !== null ? result.data.data.existing_member.hrel_telp : '',
												hrel_telp_country_code: result.data.data.existing_member.hrel_telp_country_code !== null ? result.data.data.existing_member.hrel_telp_country_code : '',
												hrell_type: result.data.data.existing_member.hrell_type !== null ? result.data.data.existing_member.hrell_type : '',

												bank_name: bank_account ? (bank_account.bank_name === 'bca') ? search(bank_account.bank_name, this.state.master.bank) : search('other', this.state.master.bank) : '',
												bank_name_others: bank_account ? (bank_account.bank_name !== 'bca') ? search(bank_account.bank_name, this.state.master.bank_list) : '' : '',
												rdn_bank: result.data.data.existing_member ? search(result.data.data.existing_member.rdn_bank, this.state.master.rdn_bank) : '',
												bank_book_path: result.data.data.existing_member.bank_book_path !== null ? result.data.data.existing_member.bank_book_path : '',
												account_number: bank_account ? bank_account.account_number : '',
												bank_bic_code: bank_account && bank_account.bank_bic_code !== null ? bank_account.bank_bic_code : '',
												account_holder_name: bank_account && bank_account.account_holder_name ? bank_account.account_holder_name : '',
												account_currency: bank_account ? bank_account.account_currency : '',
												signature_path: result.data.data.existing_member.signature_path !== null ? result.data.data.existing_member.signature_path : '',
											},
											redirectRegistration: true,
											errors: {
												global_type: '',
												global_message: '',
											},
										})

									}
								} else {
									errors['global_type'] = 'danger';
									errors['global_message'] = result.data.message;
									this.setState({
										errors: errors
									});
								}
							}, (error) => {
								console.log('/api/lead');
								console.log(error);
								errors['global_type'] = 'danger';
								errors['global_message'] = 'Cannot communicate with server.';
								this.setState({
									process: false,
									errors: errors
								});
							});
						} else {
							// ADD NEW LEAD
							const param = {
								lead_name: fields['fullname'],
								lead_dob: fields['dob'],
								lead_kitas: fields['identitynumber'],
								lead_phone: fields['phonenumber'],
								lead_email: fields['email']
							}
							axios.post(link_api + '/api/lead', queryString.stringify(param), headers).then(result => {
								this.setState({
									process: false,
								});
								if (result.data.status) {
									errors['global_type'] = 'success';
									errors['global_message'] = 'Thank you, our sales will contact you soon.';

									fields['fullname'] = '';
									fields['dob'] = '';
									fields['identitynumber'] = '';
									fields['phonenumber'] = '';
									fields['email'] = '';
									this.setState({
										errors: errors,
										fields: fields,
										foreignerSubmited: true
									});
								} else {
									errors['global_type'] = 'danger';
									errors['global_message'] = result.data.message;
									this.setState({
										errors: errors
									});
								}
							}, (error) => {
								errors['global_type'] = 'danger';
								errors['global_message'] = 'Cannot communicate with server.';
								this.setState({
									process: false,
									errors: errors
								});
							});
						}
					}
				});
			} else if (action.field === 'registration_general_information') {
				this.setState({
					registration: {
						...this.state.registration,
						first_name: action.values.first_name,
						middle_name: action.values.middle_name,
						last_name: action.values.last_name,
						nationality: action.values.nationality,
						place_of_birth: action.values.place_of_birth,
						date_of_birth: action.values.date_of_birth,
						sex: action.values.sex,
						marital_status: action.values.marital_status,
						spouse_name: action.values.spouse_name,
						mother_maiden_name: action.values.mother_maiden_name,
						religion: action.values.religion,
						religion_other: action.values.religion_other,
						heir_name: action.values.heir_name,
						heir_relation: action.values.heir_relation,
						educational_background: action.values.educational_background,
						income_per_annum: action.values.income_per_annum,
						source_of_fund: action.values.source_of_fund,
						source_of_fund_others: action.values.source_of_fund_others,
						investment_objectives: action.values.investment_objectives,
						asset_owner: action.values.asset_owner,
					}
				}, () => {
					// REDIRECT SUBMITTED
					let errors = { ...this.state.errors };
					const param = {
						member_id: this.state.member_id,
						nh_auth: this.state.registration.auth_token,
						data: JSON.stringify({
							first_name: this.state.registration.first_name,
							middle_name: this.state.registration.middle_name,
							last_name: this.state.registration.last_name,
							nationality: (typeof this.state.registration.nationality === 'object') ? parseInt(this.state.registration.nationality.value) : parseInt(this.state.registration.nationality),
							place_of_birth: this.state.registration.place_of_birth,
							date_of_birth: this.state.registration.date_of_birth,
							sex: this.state.registration.sex,
							marital_status: this.state.registration.marital_status,
							spouse_name: this.state.registration.spouse_name !== null ? this.state.registration.spouse_name : '',
							mother_maiden_name: this.state.registration.mother_maiden_name !== null ? this.state.registration.mother_maiden_name : '',
							religion: this.state.registration.religion !== 'other' ? this.state.registration.religion : this.state.registration.religion_other,
							heir_name: this.state.registration.heir_name !== null ? this.state.registration.heir_name : '',
							heir_relation: this.state.registration.heir_relation !== null ? this.state.registration.heir_relation : '',
							educational_background: (typeof this.state.registration.educational_background === 'object') ? parseInt(this.state.registration.educational_background.value) : this.state.registration.educational_background,
							income_per_annum: (typeof this.state.registration.income_per_annum === 'object') ? parseInt(this.state.registration.income_per_annum.value) : this.state.registration.income_per_annum,
							source_of_fund: (typeof this.state.registration.source_of_fund === 'object') ? parseInt(this.state.registration.source_of_fund.value) : this.state.registration.source_of_fund,
							source_of_fund_others: this.state.registration.source_of_fund_others ? this.state.registration.source_of_fund_others : '',
							investment_objectives: this.state.registration.investment_objectives ? (typeof this.state.registration.investment_objectives === 'object') ? parseInt(this.state.registration.investment_objectives.value) : this.state.registration.investment_objectives : '',
							asset_owner: this.state.registration.asset_owner,
						}),
					}

					axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
						if (result.data.status) {
							this.setState({
								step: this.state.step + 1
							})
						} else {
							errors['global_type'] = 'danger';
							errors['global_message'] = result.data.message;
							this.setState({
								errors: errors
							});
						}
					}, (error) => {
						console.log('/api/member');
						console.log(error);
						errors['global_type'] = 'danger';
						errors['global_message'] = 'Cannot communicate with server.';
						this.setState({
							errors: errors
						});
					});
				});
			} else if (action.field === 'registration_job_information') {
				this.setState({
					registration: {
						...this.state.registration,
						occupation: action.values.occupation,
						// occupation_others: action.values.occupation_others,
						// nature_of_business: action.values.nature_of_business,
						job_position: action.values.job_position,
						field_of_business: action.values.field_of_business,
						monthly_income_range: action.values.monthly_income_range,
						company_name: action.values.company_name,
						company_address: action.values.company_address,
						company_country: action.values.company_country,
						company_province: action.values.company_province,
						company_city: action.values.company_city,
						company_postal_code: action.values.company_postal_code,
						company_phone_area_code: action.values.company_phone_area_code,
						company_phone_number: action.values.company_phone_number,

						work_year: action.values.work_year,
						work_month: action.values.work_month,

						pos_full_name: action.values.pos_full_name,
						pos_place_of_birth: action.values.pos_place_of_birth,
						pos_dob: action.values.pos_dob,
						pos_home_phone: action.values.pos_home_phone,
						pos_mobile_phone: action.values.pos_mobile_phone,
						pos_address: action.values.pos_address,
						pos_province: action.values.pos_province,
						pos_city: action.values.pos_city,
						pos_postalcode: action.values.pos_postalcode,
						pos_id_type: action.values.pos_id_type,
						pos_idcard_no: action.values.pos_idcard_no,
						pos_idcard_expdate: action.values.pos_idcard_expdate,
						pos_occupation_code: action.values.pos_occupation_code,
						pos_occupation_name: action.values.pos_occupation_name,
						pos_company_name: action.values.pos_company_name,
						pos_company_address: action.values.pos_company_address,
						pos_company_province: action.values.pos_company_province,
						pos_company_city: action.values.pos_company_city,
						pos_company_postalcode: action.values.pos_company_postalcode,
						pos_company_phone_number: action.values.pos_company_phone_number,
						pos_company_fax: action.values.pos_company_fax,
						pos_annual_income: action.values.pos_annual_income,
						pos_source_of_income: action.values.pos_source_of_income,
						pos_gender: action.values.pos_gender,
						pos_marital_status: action.values.pos_marital_status,
						pos_family_relationship: action.values.pos_family_relationship,
						pos_email_address: action.values.pos_email_address,
						pos_rt: action.values.pos_rt,
						pos_rw: action.values.pos_rw,
						pos_district: action.values.pos_district,
						pos_subdistrict: action.values.pos_subdistrict,
						pos_npwp_number: action.values.pos_npwp_number,
						pos_no_npwp_reason: action.values.pos_no_npwp_reason,

						company_rt: action.values.company_rt,
						company_rw: action.values.company_rw,
						company_subdistrict: action.values.company_subdistrict,
						company_district: action.values.company_district,

						company_district_master: action.values.company_district_master,						
						company_subdistrict_master: action.values.company_subdistrict_master,

						pos_district_master: action.values.pos_district_master,
						pos_subdistrict_master: action.values.pos_subdistrict_master,

						pos_ktp_file: action.values.pos_ktp_file,
						pos_npwp_file: action.values.pos_npwp_file

					}
				}, () => {
					// Proses dulu upload image jika ada
					let errors = { ...this.state.errors };
					const paramUploadFile = new FormData();

					if(this.state.registration.pos_ktp_file || this.state.registration.pos_npwp_file) {
						paramUploadFile.append('member_id', this.state.member_id);
						paramUploadFile.append('nh_auth', this.state.registration.auth_token);

						let stringNationality = (typeof this.state.registration.nationality === 'object') ? (parseInt(this.state.registration.nationality.value) === 104 ? 'wni' : 'wna') : (parseInt(this.state.registration.nationality) === 104 ? 'wni' : 'wna');
						paramUploadFile.append('nationality', stringNationality);						

						if (this.state.registration.pos_ktp_file) {
							paramUploadFile.append('pos_ktp_file', this.state.registration.pos_ktp_file);
						}

						if (this.state.registration.pos_npwp_file) {
							paramUploadFile.append('pos_npwp_file', this.state.registration.pos_npwp_file);
						}

						// upload file
						axios.post(link_api + '/api/upload', paramUploadFile, {
							headers: {
								'content-type': 'multipart/form-data'
							}
						}).then(result => {
							this.setState({
								registration: {
									...this.state.registration,
									pos_ktp_path: result.data.data.pos_ktp_path,
									pos_npwp_path: result.data.data.pos_npwp_path,
								}
							});

							if (result.data.status) {
								const param = {
									member_id: this.state.member_id,
									nh_auth: this.state.registration.auth_token,
									data: JSON.stringify({
										occupation: (typeof this.state.registration.occupation === 'object') ? parseInt(this.state.registration.occupation.value) : this.state.registration.occupation,
										// occupation_others: this.state.registration.occupation_others,
										// nature_of_business: this.state.registration.nature_of_business,
										job_position: ((typeof this.state.registration.occupation === 'object' && parseInt(this.state.registration.occupation.value) === 1) || parseInt(this.state.registration.occupation === 1)) ? 1 : (typeof this.state.registration.job_position === 'object') ? this.state.registration.job_position.value : this.state.registration.job_position,
										
										field_of_business: ((typeof this.state.registration.occupation === 'object' && parseInt(this.state.registration.occupation.value) === 1) || parseInt(this.state.registration.occupation === 1)) ? 1 : (typeof this.state.registration.field_of_business === 'object') ? this.state.registration.field_of_business.value : this.state.registration.field_of_business,
								
										monthly_income_range: (this.state.registration.monthly_income_range) ? (typeof this.state.registration.monthly_income_range === 'object') ? parseInt(this.state.registration.monthly_income_range.value) : this.state.registration.monthly_income_range : null,
								
										company_name: this.state.registration.company_name,
										company_address: this.state.registration.company_address,
										company_country: (this.state.registration.company_country) ? (typeof this.state.registration.company_country === 'object') ? parseInt(this.state.registration.company_country.value) : this.state.registration.company_country : '0',
										company_province: (this.state.registration.company_province) ? (typeof this.state.registration.company_province === 'object') ? parseInt(this.state.registration.company_province.value) : this.state.registration.company_province : '0',
										company_city: (this.state.registration.company_city) ? (typeof this.state.registration.company_city === 'object') ? parseInt(this.state.registration.company_city.value) : this.state.registration.company_city : '0',
										company_postal_code: this.state.registration.company_postal_code,
										company_phone_area_code: this.state.registration.company_phone_area_code,
										company_phone_number: this.state.registration.company_phone_number.replace(/ /g, ""),
								
										company_rt: this.state.registration.company_rt,
										company_rw: this.state.registration.company_rw,
										company_subdistrict: this.state.registration.company_subdistrict,
										company_district: this.state.registration.company_district,
								
										company_district_master: (this.state.registration.company_district_master) ? (typeof this.state.registration.company_district_master === 'object') ? parseInt(this.state.registration.company_district_master.value) : this.state.registration.company_district_master : '0',
								
										company_subdistrict_master: (this.state.registration.company_subdistrict_master) ? (typeof this.state.registration.company_subdistrict_master === 'object') ? parseInt(this.state.registration.company_subdistrict_master.value) : this.state.registration.company_subdistrict_master : '0',							
								
										work_year: this.state.registration.work_year ? this.state.registration.work_year : '0',
										work_month: this.state.registration.work_month ? this.state.registration.work_month : '0',
								
										pos_full_name: this.state.registration.pos_full_name,
										pos_place_of_birth: this.state.registration.pos_place_of_birth,
										pos_dob: this.state.registration.pos_dob,
										pos_home_phone: this.state.registration.pos_home_phone !== null ? this.state.registration.pos_home_phone.replace(/ /g, "") : this.state.registration.pos_home_phone,
										pos_mobile_phone: this.state.registration.pos_mobile_phone !== null ? this.state.registration.pos_mobile_phone.replace(/ /g, "") : this.state.registration.pos_mobile_phone,
										pos_address: this.state.registration.pos_address,
										pos_province: (this.state.registration.pos_province) ? (typeof this.state.registration.pos_province === 'object') ? parseInt(this.state.registration.pos_province.value) : this.state.registration.pos_province : '0',
										pos_city: (this.state.registration.pos_city) ? (typeof this.state.registration.pos_city === 'object') ? parseInt(this.state.registration.pos_city.value) : this.state.registration.pos_city : '0',
										pos_postalcode: this.state.registration.pos_postalcode,
										pos_id_type: (this.state.registration.pos_id_type) ? (typeof this.state.registration.pos_id_type === 'object') ? parseInt(this.state.registration.pos_id_type.value) : this.state.registration.pos_id_type : '0',
										pos_idcard_no: this.state.registration.pos_idcard_no,
										pos_idcard_expdate: (this.state.registration.pos_idcard_expdate) ? this.state.registration.pos_idcard_expdate : '0001-01-01',
										pos_occupation_code: (this.state.registration.pos_occupation_code) ? (typeof this.state.registration.pos_occupation_code === 'object') ? parseInt(this.state.registration.pos_occupation_code.value) : this.state.registration.pos_occupation_code : '0',
										pos_occupation_name: this.state.registration.pos_occupation_name,
										pos_company_name: this.state.registration.pos_company_name,
										pos_company_address: this.state.registration.pos_company_address,
										pos_company_province: (this.state.registration.pos_company_province) ? (typeof this.state.registration.pos_company_province === 'object') ? parseInt(this.state.registration.pos_company_province.value) : this.state.registration.pos_company_province : '0',
										pos_company_city: (this.state.registration.pos_company_city) ? (typeof this.state.registration.pos_company_city === 'object') ? parseInt(this.state.registration.pos_company_city.value) : this.state.registration.pos_company_city : '0',
										pos_company_postalcode: this.state.registration.pos_company_postalcode,
										pos_company_phone_number: this.state.registration.pos_company_phone_number !== null ? this.state.registration.pos_company_phone_number.replace(/ /g, "") : this.state.registration.pos_company_phone_number,
										pos_company_fax: this.state.registration.pos_company_fax !== null ? this.state.registration.pos_company_fax.replace(/ /g, "") : this.state.registration.pos_company_fax,
										pos_annual_income: (this.state.registration.pos_annual_income) ? (typeof this.state.registration.pos_annual_income === 'object') ? parseInt(this.state.registration.pos_annual_income.value) : this.state.registration.pos_annual_income : '0',
										pos_source_of_income: (this.state.registration.pos_source_of_income) ? (typeof this.state.registration.pos_source_of_income === 'object') ? parseInt(this.state.registration.pos_source_of_income.value) : this.state.registration.pos_source_of_income : '0',
										pos_gender: (this.state.registration.pos_gender) ? (typeof this.state.registration.pos_gender === 'object') ? this.state.registration.pos_gender.value : this.state.registration.pos_gender : "",
								
										pos_marital_status: (this.state.registration.pos_marital_status) ? (typeof this.state.registration.pos_marital_status === 'object') ? parseInt(this.state.registration.pos_marital_status.value) : this.state.registration.pos_marital_status : "0",
								
										pos_family_relationship: (this.state.registration.pos_family_relationship) ? (typeof this.state.registration.pos_family_relationship === 'object') ? parseInt(this.state.registration.pos_family_relationship.value) : this.state.registration.pos_family_relationship : "0",
								
										pos_email_address: this.state.registration.pos_email_address,
										pos_rt: this.state.registration.pos_rt,
										pos_rw: this.state.registration.pos_rw,
										pos_district: this.state.registration.pos_district,
										pos_subdistrict: this.state.registration.pos_subdistrict,
								
										pos_district_master: (this.state.registration.pos_district_master) ? (typeof this.state.registration.pos_district_master === 'object') ? parseInt(this.state.registration.pos_district_master.value) : this.state.registration.pos_district_master : '0',
										pos_subdistrict_master: (this.state.registration.pos_subdistrict_master) ? (typeof this.state.registration.pos_subdistrict_master === 'object') ? parseInt(this.state.registration.pos_subdistrict_master.value) : this.state.registration.pos_subdistrict_master : '0',							
								
										pos_npwp_number: this.state.registration.pos_npwp_number,
										pos_no_npwp_reason: (this.state.registration.pos_no_npwp_reason) ? (typeof this.state.registration.pos_no_npwp_reason === 'object') ? parseInt(this.state.registration.pos_no_npwp_reason.value) : this.state.registration.pos_no_npwp_reason : "0",
									}),
								}
								
								axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
									if (result.data.status) {
										this.setState({
											step: this.state.step + 1
										})
									} else {
										errors['global_type'] = 'danger';
										errors['global_message'] = result.data.message;
										this.setState({
											errors: errors
										});
									}
								}, (error) => {
									console.log('/api/member');
									console.log(error);
									errors['global_type'] = 'danger';
									errors['global_message'] = 'Cannot communicate with server.';
									this.setState({
										errors: errors
									});
								});						
							}
						}, (error) => {
							console.log('/api/upload');
							console.log(error);
							errors['global_type'] = 'danger';
							errors['global_message'] = 'Cannot communicate with server.';
							this.setState({
								errors: errors
							});
						});						
					} else {

						// jika tidak ada file BO yg diupload
						const param = {
							member_id: this.state.member_id,
							nh_auth: this.state.registration.auth_token,
							data: JSON.stringify({
								occupation: (typeof this.state.registration.occupation === 'object') ? parseInt(this.state.registration.occupation.value) : this.state.registration.occupation,
								// occupation_others: this.state.registration.occupation_others,
								// nature_of_business: this.state.registration.nature_of_business,
								job_position: ((typeof this.state.registration.occupation === 'object' && parseInt(this.state.registration.occupation.value) === 1) || parseInt(this.state.registration.occupation === 1)) ? 1 : (typeof this.state.registration.job_position === 'object') ? this.state.registration.job_position.value : this.state.registration.job_position,
								
								field_of_business: ((typeof this.state.registration.occupation === 'object' && parseInt(this.state.registration.occupation.value) === 1) || parseInt(this.state.registration.occupation === 1)) ? 1 : (typeof this.state.registration.field_of_business === 'object') ? this.state.registration.field_of_business.value : this.state.registration.field_of_business,
						
								monthly_income_range: (this.state.registration.monthly_income_range) ? (typeof this.state.registration.monthly_income_range === 'object') ? parseInt(this.state.registration.monthly_income_range.value) : this.state.registration.monthly_income_range : null,
						
								company_name: this.state.registration.company_name,
								company_address: this.state.registration.company_address,
								company_country: (this.state.registration.company_country) ? (typeof this.state.registration.company_country === 'object') ? parseInt(this.state.registration.company_country.value) : this.state.registration.company_country : '0',
								company_province: (this.state.registration.company_province) ? (typeof this.state.registration.company_province === 'object') ? parseInt(this.state.registration.company_province.value) : this.state.registration.company_province : '0',
								company_city: (this.state.registration.company_city) ? (typeof this.state.registration.company_city === 'object') ? parseInt(this.state.registration.company_city.value) : this.state.registration.company_city : '0',
								company_postal_code: this.state.registration.company_postal_code,
								company_phone_area_code: this.state.registration.company_phone_area_code,
								company_phone_number: this.state.registration.company_phone_number.replace(/ /g, ""),
						
								company_rt: this.state.registration.company_rt,
								company_rw: this.state.registration.company_rw,
								company_subdistrict: this.state.registration.company_subdistrict,
								company_district: this.state.registration.company_district,
						
								company_district_master: (this.state.registration.company_district_master) ? (typeof this.state.registration.company_district_master === 'object') ? parseInt(this.state.registration.company_district_master.value) : this.state.registration.company_district_master : '0',
						
								company_subdistrict_master: (this.state.registration.company_subdistrict_master) ? (typeof this.state.registration.company_subdistrict_master === 'object') ? parseInt(this.state.registration.company_subdistrict_master.value) : this.state.registration.company_subdistrict_master : '0',							
						
								work_year: this.state.registration.work_year ? this.state.registration.work_year : '0',
								work_month: this.state.registration.work_month ? this.state.registration.work_month : '0',
						
								pos_full_name: this.state.registration.pos_full_name,
								pos_place_of_birth: this.state.registration.pos_place_of_birth,
								pos_dob: this.state.registration.pos_dob,
								pos_home_phone: this.state.registration.pos_home_phone !== null ? this.state.registration.pos_home_phone.replace(/ /g, "") : this.state.registration.pos_home_phone,
								pos_mobile_phone: this.state.registration.pos_mobile_phone !== null ? this.state.registration.pos_mobile_phone.replace(/ /g, "") : this.state.registration.pos_mobile_phone,
								pos_address: this.state.registration.pos_address,
								pos_province: (this.state.registration.pos_province) ? (typeof this.state.registration.pos_province === 'object') ? parseInt(this.state.registration.pos_province.value) : this.state.registration.pos_province : '0',
								pos_city: (this.state.registration.pos_city) ? (typeof this.state.registration.pos_city === 'object') ? parseInt(this.state.registration.pos_city.value) : this.state.registration.pos_city : '0',
								pos_postalcode: this.state.registration.pos_postalcode,
								pos_id_type: (this.state.registration.pos_id_type) ? (typeof this.state.registration.pos_id_type === 'object') ? parseInt(this.state.registration.pos_id_type.value) : this.state.registration.pos_id_type : '0',
								pos_idcard_no: this.state.registration.pos_idcard_no,
								pos_idcard_expdate: (this.state.registration.pos_idcard_expdate) ? this.state.registration.pos_idcard_expdate : '0001-01-01',
								pos_occupation_code: (this.state.registration.pos_occupation_code) ? (typeof this.state.registration.pos_occupation_code === 'object') ? parseInt(this.state.registration.pos_occupation_code.value) : this.state.registration.pos_occupation_code : '0',
								pos_occupation_name: this.state.registration.pos_occupation_name,
								pos_company_name: this.state.registration.pos_company_name,
								pos_company_address: this.state.registration.pos_company_address,
								pos_company_province: (this.state.registration.pos_company_province) ? (typeof this.state.registration.pos_company_province === 'object') ? parseInt(this.state.registration.pos_company_province.value) : this.state.registration.pos_company_province : '0',
								pos_company_city: (this.state.registration.pos_company_city) ? (typeof this.state.registration.pos_company_city === 'object') ? parseInt(this.state.registration.pos_company_city.value) : this.state.registration.pos_company_city : '0',
								pos_company_postalcode: this.state.registration.pos_company_postalcode,
								pos_company_phone_number: this.state.registration.pos_company_phone_number !== null ? this.state.registration.pos_company_phone_number.replace(/ /g, "") : this.state.registration.pos_company_phone_number,
								pos_company_fax: this.state.registration.pos_company_fax !== null ? this.state.registration.pos_company_fax.replace(/ /g, "") : this.state.registration.pos_company_fax,
								pos_annual_income: (this.state.registration.pos_annual_income) ? (typeof this.state.registration.pos_annual_income === 'object') ? parseInt(this.state.registration.pos_annual_income.value) : this.state.registration.pos_annual_income : '0',
								pos_source_of_income: (this.state.registration.pos_source_of_income) ? (typeof this.state.registration.pos_source_of_income === 'object') ? parseInt(this.state.registration.pos_source_of_income.value) : this.state.registration.pos_source_of_income : '0',
								pos_gender: (this.state.registration.pos_gender) ? (typeof this.state.registration.pos_gender === 'object') ? this.state.registration.pos_gender.value : this.state.registration.pos_gender : "",
						
								pos_marital_status: (this.state.registration.pos_marital_status) ? (typeof this.state.registration.pos_marital_status === 'object') ? parseInt(this.state.registration.pos_marital_status.value) : this.state.registration.pos_marital_status : "0",
						
								pos_family_relationship: (this.state.registration.pos_family_relationship) ? (typeof this.state.registration.pos_family_relationship === 'object') ? parseInt(this.state.registration.pos_family_relationship.value) : this.state.registration.pos_family_relationship : "0",
						
								pos_email_address: this.state.registration.pos_email_address,
								pos_rt: this.state.registration.pos_rt,
								pos_rw: this.state.registration.pos_rw,
								pos_district: this.state.registration.pos_district,
								pos_subdistrict: this.state.registration.pos_subdistrict,
						
								pos_district_master: (this.state.registration.pos_district_master) ? (typeof this.state.registration.pos_district_master === 'object') ? parseInt(this.state.registration.pos_district_master.value) : this.state.registration.pos_district_master : '0',
								pos_subdistrict_master: (this.state.registration.pos_subdistrict_master) ? (typeof this.state.registration.pos_subdistrict_master === 'object') ? parseInt(this.state.registration.pos_subdistrict_master.value) : this.state.registration.pos_subdistrict_master : '0',							
						
								pos_npwp_number: this.state.registration.pos_npwp_number,
								pos_no_npwp_reason: (this.state.registration.pos_no_npwp_reason) ? (typeof this.state.registration.pos_no_npwp_reason === 'object') ? parseInt(this.state.registration.pos_no_npwp_reason.value) : this.state.registration.pos_no_npwp_reason : "0",
							}),
						}
						
						axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
							if (result.data.status) {
								this.setState({
									step: this.state.step + 1
								})
							} else {
								errors['global_type'] = 'danger';
								errors['global_message'] = result.data.message;
								this.setState({
									errors: errors
								});
							}
						}, (error) => {
							console.log('/api/member');
							console.log(error);
							errors['global_type'] = 'danger';
							errors['global_message'] = 'Cannot communicate with server.';
							this.setState({
								errors: errors
							});
						});						
					}
				});
			} else if (action.field === 'registration_card_information') {
				this.setState({
					registration: {
						...this.state.registration,
						ktp_number: action.values.ktp_number,
						ktp_file: action.values.ktp_file,
						// ktp_expired_date: action.values.ktp_expired_date,
						npwp_number: action.values.npwp_number,
						npwp_file: action.values.npwp_file,
						npwp_registration_date: action.values.npwp_registration_date,
						no_npwp_reason: action.values.no_npwp_reason,
						passport_number: action.values.passport_number,
						passport_file: action.values.passport_file,
						passport_expired_date: action.values.passport_expired_date,
						kitas: action.values.kitas,
						kitas_file: action.values.kitas_file,
						kitas_expired_date: action.values.kitas_expired_date,
						self_photo_file: action.values.self_photo_file,
					}
				}, () => {
					let errors = { ...this.state.errors };
					const paramUploadFile = new FormData();
					if (this.state.registration.ktp_file || this.state.registration.npwp_file || this.state.registration.passport_file || this.state.registration.kitas_file || this.state.registration.self_photo_file) {
						paramUploadFile.append('member_id', this.state.member_id);
						paramUploadFile.append('nh_auth', this.state.registration.auth_token);
						let stringNationality = (typeof this.state.registration.nationality === 'object') ? (parseInt(this.state.registration.nationality.value) === 104 ? 'wni' : 'wna') : (parseInt(this.state.registration.nationality) === 104 ? 'wni' : 'wna');
						paramUploadFile.append('nationality', stringNationality);
						if (this.state.registration.ktp_file) {
							paramUploadFile.append('ktp_file', this.state.registration.ktp_file);
						}
						if (this.state.registration.npwp_file) {
							paramUploadFile.append('npwp_file', this.state.registration.npwp_file);
						}
						if (this.state.registration.passport_file) {
							paramUploadFile.append('passport_file', this.state.registration.passport_file);
						}
						if (this.state.registration.kitas_file) {
							paramUploadFile.append('kitas_file', this.state.registration.kitas_file);
						}
						if (this.state.registration.self_photo_file) {
							paramUploadFile.append('self_photo_file', this.state.registration.self_photo_file);
						}
						axios.post(link_api + '/api/upload', paramUploadFile, {
							headers: {
								'content-type': 'multipart/form-data'
							}
						}).then(result => {
							this.setState({
								registration: {
									...this.state.registration,
									ktp_path: result.data.data.ktp_path,
									npwp_path: result.data.data.npwp_path,
									passport_path: result.data.data.passport_file_path,
									kitas_path: result.data.data.kitas_file_path,
									self_photo_path: result.data.data.self_photo_path,
								}
							});

							if (result.data.status) {
								const param = {
									member_id: this.state.member_id,
									nh_auth: this.state.registration.auth_token,
									data: JSON.stringify({
										ktp_number: this.state.registration.ktp_number,
										// ktp_expired_date: (this.state.registration.ktp_expired_date !== 'lifetime') ? this.state.registration.ktp_expired_date : '0001-01-01',
										npwp_number: this.state.registration.npwp_number !== null ? this.state.registration.npwp_number : '',
										npwp_registration_date: (this.state.registration.npwp_registration_date) ? this.state.registration.npwp_registration_date : '0001-01-01',
										no_npwp_reason: (this.state.registration.no_npwp_reason) ? (typeof this.state.registration.no_npwp_reason === 'object') ? this.state.registration.no_npwp_reason.value : this.state.registration.no_npwp_reason : null,
										passport_number: this.state.registration.passport_number !== null ? this.state.registration.passport_number : '',
										passport_expired_date: (this.state.registration.passport_expired_date) ? this.state.registration.passport_expired_date : '0001-01-01',
										kitas: this.state.registration.kitas !== null ? this.state.registration.kitas : '',
										kitas_expired_date: (this.state.registration.kitas_expired_date) ? this.state.registration.kitas_expired_date : '0001-01-01',
									}),
								}

								axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
									if (result.data.status) {
										this.setState({
											step: this.state.step + 1
										})
									} else {
										errors['global_type'] = 'danger';
										errors['global_message'] = result.data.message;
										this.setState({
											errors: errors
										});
									}
								}, (error) => {
									console.log('/api/member');
									console.log(error);
									errors['global_type'] = 'danger';
									errors['global_message'] = 'Cannot communicate with server.';
									this.setState({
										errors: errors
									});
								});
							}
						}, (error) => {
							console.log('/api/upload');
							console.log(error);
							errors['global_type'] = 'danger';
							errors['global_message'] = 'Cannot communicate with server.';
							this.setState({
								errors: errors
							});
						});
					} else {
						if (this.state.registration.ktp_path && this.state.registration.self_photo_path) {
							const param = {
								member_id: this.state.member_id,
								nh_auth: this.state.registration.auth_token,
								data: JSON.stringify({
									ktp_number: this.state.registration.ktp_number,
									// ktp_expired_date: (this.state.registration.ktp_expired_date !== 'lifetime') ? this.state.registration.ktp_expired_date : '0001-01-01',
									npwp_number: this.state.registration.npwp_number !== null ? this.state.registration.npwp_number : '',
									npwp_registration_date: (this.state.registration.npwp_registration_date) ? this.state.registration.npwp_registration_date : '0001-01-01',
									no_npwp_reason: (this.state.registration.no_npwp_reason) ? (typeof this.state.registration.no_npwp_reason === 'object') ? this.state.registration.no_npwp_reason.value : this.state.registration.no_npwp_reason : null,
									passport_number: this.state.registration.passport_number !== null ? this.state.registration.passport_number : '',
									passport_expired_date: (this.state.registration.passport_expired_date) ? this.state.registration.passport_expired_date : '0001-01-01',
									kitas: this.state.registration.kitas !== null ? this.state.registration.kitas : '',
									kitas_expired_date: (this.state.registration.kitas_expired_date) ? this.state.registration.kitas_expired_date : '0001-01-01',
								}),
							}

							axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
								if (result.data.status) {
									this.setState({
										step: this.state.step + 1
									})
								} else {
									errors['global_type'] = 'danger';
									errors['global_message'] = result.data.message;
									this.setState({
										errors: errors
									});
								}
							}, (error) => {
								console.log('/api/member');
								console.log(error);
								errors['global_type'] = 'danger';
								errors['global_message'] = 'Cannot communicate with server.';
								this.setState({
									errors: errors
								});
							});
						}
					}
				});
			} else if (action.field === 'registration_contact_for_correspondence') {
				this.setState({
					registration: {
						...this.state.registration,
						address_1: action.values.address_1,
						address_2: action.values.address_2,
						address_3: action.values.address_3,
						country: action.values.country,
						province: action.values.province,
						city: action.values.city,
						district: action.values.district,
						subdistrict: action.values.subdistrict,
						district_master: action.values.district_master,
						subdistrict_master: action.values.subdistrict_master,
						rt: action.values.rt,
						rw: action.values.rw,
						postal_code: action.values.postal_code,
						home_phone: action.values.home_phone.trim(),
						home_phone_area_code: action.values.home_phone_area_code.trim(),
						mobile_phone: action.values.mobile_phone,
						mobile_phone_country_code: action.values.mobile_phone_country_code,
						mobile_phone_country_dialcode: action.values.mobile_phone_country_dialcode,
						fax_number: action.values.fax_number,
						email_address: action.values.email_address,
						hrel_name: action.values.hrel_name,
						hrel_address: action.values.hrel_address,
						hrel_telp: action.values.hrel_telp,
						hrel_telp_country_code: action.values.hrel_telp_country_code,
						hrel_telp_country_dialcode: action.values.hrel_telp_country_dialcode,
						hrell_type: action.values.hrell_type,
					}
				}, () => {
					// REDIRECT SUBMITTED
					let errors = { ...this.state.errors };
					const param = {
						member_id: this.state.member_id,
						nh_auth: this.state.registration.auth_token,
						data: JSON.stringify({
							address_1: this.state.registration.address_1,
							// address_2: this.state.registration.address_2,
							// address_3: this.state.registration.address_3,
							country: (this.state.registration.country) ? (typeof this.state.registration.country === 'object') ? parseInt(this.state.registration.country.value) : this.state.registration.country : '',
							province: (this.state.registration.province) ? (typeof this.state.registration.province === 'object') ? parseInt(this.state.registration.province.value) : this.state.registration.province : '',
							city: (this.state.registration.city) ? (typeof this.state.registration.city === 'object') ? parseInt(this.state.registration.city.value) : this.state.registration.city : '',
							district: this.state.registration.district,
							subdistrict: this.state.registration.subdistrict,
							district_master: (this.state.registration.district_master) ? (typeof this.state.registration.district_master === 'object') ? parseInt(this.state.registration.district_master.value) : this.state.registration.district_master : '',
							subdistrict_master: (this.state.registration.subdistrict_master) ? (typeof this.state.registration.subdistrict_master === 'object') ? parseInt(this.state.registration.subdistrict_master.value) : this.state.registration.subdistrict_master : '',
							rt: this.state.registration.rt,
							rw: this.state.registration.rw,
							postal_code: this.state.registration.postal_code,
							home_phone: this.state.registration.home_phone.replace(/ /g, ""),
							home_phone_area_code: this.state.registration.home_phone_area_code,
							mobile_phone: this.state.registration.mobile_phone.replace(/ /g, ""),
							mobile_phone_country_code: this.state.registration.mobile_phone_country_code,
							mobile_phone_country_dialcode: this.state.registration.mobile_phone_country_dialcode,
							// fax_number: this.state.registration.fax_number,
							email_address: this.state.registration.email_address,
							hrel_name: this.state.registration.hrel_name,
							hrel_address: this.state.registration.hrel_address,
							hrel_telp: this.state.registration.hrel_telp.replace(/ /g, ""),
							hrel_telp_country_code: this.state.registration.hrel_telp_country_code,
							hrel_telp_country_dialcode: this.state.registration.hrel_telp_country_dialcode,
							hrell_type: this.state.registration.hrell_type,
						}),
					}

					axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
						if (result.data.status) {
							this.setState({
								step: this.state.step + 1
							})
						} else {
							errors['global_type'] = 'danger';
							errors['global_message'] = result.data.message;
							this.setState({
								errors: errors
							});
						}
					}, (error) => {
						console.log('/api/member');
						console.log(error);
						errors['global_type'] = 'danger';
						errors['global_message'] = 'Cannot communicate with server.';
						this.setState({
							errors: errors
						});
					});
				});
			} else if (action.field === 'registration_other_contact') {
				const form = action.event.currentTarget;
				action.event.preventDefault();
				action.event.stopPropagation();
				if (form.checkValidity() !== false) {
					// REDIRECT SUBMITTED
					let errors = { ...this.state.errors };
					const param = {
						member_id: this.state.member_id,
						nh_auth: this.state.registration.auth_token,
						data: JSON.stringify({
							other_contact: JSON.stringify({
								address_1: this.state.registration.other_contact_address_1,
								address_2: this.state.registration.other_contact_address_2,
								address_3: this.state.registration.other_contact_address_3,
								country: (this.state.registration.other_contact_country) ? (typeof this.state.registration.other_contact_country === 'object') ? parseInt(this.state.registration.other_contact_country.value) : this.state.registration.other_contact_country : null,
								province: (this.state.registration.other_contact_province) ? (typeof this.state.registration.other_contact_province === 'object') ? parseInt(this.state.registration.other_contact_province.value) : this.state.registration.other_contact_province : null,
								city: (this.state.registration.other_contact_city) ? (typeof this.state.registration.other_contact_city === 'object') ? parseInt(this.state.registration.other_contact_city.value) : this.state.registration.other_contact_city : null,
								postal_code: this.state.registration.other_contact_postal_code,
								home_phone: this.state.registration.other_contact_home_phone,
								mobile_phone: this.state.registration.other_contact_mobile_phone,
								fax_number: this.state.registration.other_contact_fax_number,
								email_address: this.state.registration.other_contact_email_address,
								hrel_name: this.state.registration.other_contact_hrel_name,
								hrel_address: this.state.registration.other_contact_hrel_address,
								hrel_telp: this.state.registration.other_contact_hrel_telp,
								hrell_type: this.state.registration.other_contact_hrell_type,
							})
						}),
					}

					axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
						if (result.data.status) {
							this.setState({
								step: this.state.step + 1
							})
						} else {
							errors['global_type'] = 'danger';
							errors['global_message'] = result.data.message;
							this.setState({
								errors: errors
							});
						}
					}, (error) => {
						console.log('/api/member');
						console.log(error);
						errors['global_type'] = 'danger';
						errors['global_message'] = 'Cannot communicate with server.';
						this.setState({
							errors: errors
						});
					});
				}

				this.setState({
					errors: validate(form),
					validated: true
				});
			} else if (action.field === 'registration_bank_account') {
				this.setState({
					registration: {
						...this.state.registration,
						bank_name: action.values.bank_name,
						bank_name_others: action.values.bank_name_others,
						rdn_bank: action.values.rdn_bank,
						bank_book_file: action.values.bank_book_file,
						account_number: action.values.account_number,
						bank_bic_code: action.values.bank_bic_code,
						account_holder_name: action.values.account_holder_name,
						npwp_number: action.values.npwp_number,
						npwp_file: action.values.npwp_file,
						npwp_registration_date: action.values.npwp_registration_date,
						account_currency: action.values.account_currency,
						signature_raw: action.values.signature_raw,
						bca_agree1: action.values.bca_agree1,
						bca_agree2: action.values.bca_agree2,
						bca_agree3: action.values.bca_agree3,
						cimb_agree1: action.values.cimb_agree1,
						cimb_agree2: action.values.cimb_agree2,
					},
					next_action: true,
				}, () => {
					const paramUploadFile = new FormData();
					let errors = { ...this.state.errors };
					if (this.state.registration.signature_raw || this.state.registration.bank_book_file) {
						paramUploadFile.append('member_id', this.state.member_id);
						paramUploadFile.append('nh_auth', this.state.registration.auth_token);
						let stringNationality = (typeof this.state.registration.nationality === 'object') ? (parseInt(this.state.registration.nationality.value) === 104 ? 'wni' : 'wna') : (parseInt(this.state.registration.nationality) === 104 ? 'wni' : 'wna');
						paramUploadFile.append('nationality', stringNationality);
						if (this.state.registration.signature_raw) {
							paramUploadFile.append('signature_raw', this.state.registration.signature_raw);
						}
						if (this.state.registration.bank_book_file) {
							paramUploadFile.append('bank_book_file', this.state.registration.bank_book_file);
						}
						if (this.state.registration.npwp_file) {
							paramUploadFile.append('npwp_file', this.state.registration.npwp_file);
						}
						axios.post(link_api + '/api/upload', paramUploadFile, {
							headers: {
								'content-type': 'multipart/form-data'
							}
						}).then(result => {

							this.setState({
								registration: {
									...this.state.registration,
									signature_path: result.data.data.signature_path,
									bank_book_path: result.data.data.bank_book_path,
									npwp_path: result.data.data.npwp_path,
								}
							});

							if (result.data.status) {
								let bank_name = '';

								if (typeof this.state.registration.bank_name === 'object') {
									if (this.state.registration.bank_name.value === 'other') {
										if (typeof this.state.registration.bank_name_others === 'object') {
											bank_name = this.state.registration.bank_name_others.value;
										} else {
											bank_name = this.state.registration.bank_name_others;
										}
									} else {
										bank_name = this.state.registration.bank_name.value;
									}
								} else {
									if (this.state.registration.bank_name === 'other') {
										if (typeof this.state.registration.bank_name_others === 'object') {
											bank_name = this.state.registration.bank_name_others.value;
										} else {
											bank_name = this.state.registration.bank_name_others;
										}
									} else {
										bank_name = this.state.registration.bank_name;
									}
								}
								// REDIRECT SUMMARY
								let errors = { ...this.state.errors };
								const param = {
									member_id: this.state.member_id,
									nh_auth: this.state.registration.auth_token,
									data: JSON.stringify({
										bank_account: [{
											bank_name: bank_name,
											account_number: this.state.registration.account_number,
											// bank_bic_code: (this.state.registration.bank_name) ? (typeof this.state.registration.bank_name === 'object') ? this.state.master.bank.find(({value}) => value === this.state.registration.bank_name.value).code : this.state.master.bank.find(({value}) => value === this.state.registration.bank_name).code : null,
											account_holder_name: (this.state.registration.account_holder_name) ? this.state.registration.account_holder_name : null,
											account_currency: (this.state.registration.account_currency) ? this.state.registration.account_currency : 0,
										}],
										rdn_bank: bank_name === 'bca' ? bank_name : (typeof this.state.registration.rdn_bank === 'object') ? this.state.registration.rdn_bank.value : this.state.registration.rdn_bank,
										npwp_number: this.state.registration.npwp_number !== null ? this.state.registration.npwp_number : '',
										npwp_registration_date: (this.state.registration.npwp_registration_date) ? this.state.registration.npwp_registration_date : '0001-01-01',
									})
								}

								axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
									if (result.data.status) {
										this.setState({
											redirectSummary: true,
											next_action: false,
										})
									} else {
										errors['global_type'] = 'danger';
										errors['global_message'] = result.data.message;
										this.setState({
											errors: errors,
											next_action: false,
										});
									}
								}, (error) => {
									console.log('/api/member');
									console.log(error);
									errors['global_type'] = 'danger';
									errors['global_message'] = 'Cannot communicate with server.';
									this.setState({
										errors: errors
									});
								});
							}

						}, (error) => {
							console.log('/api/upload');
							console.log(error);
							errors['global_type'] = 'danger';
							errors['global_message'] = 'Cannot communicate with server.';
							this.setState({
								errors: errors
							});
						});
					} else {
						if (this.state.registration.signature_path && this.state.registration.bank_book_path) {
							let bank_name = '';

							if (typeof this.state.registration.bank_name === 'object') {
								if (this.state.registration.bank_name.value === 'other') {
									if (typeof this.state.registration.bank_name_others === 'object') {
										bank_name = this.state.registration.bank_name_others.value;
									} else {
										bank_name = this.state.registration.bank_name_others;
									}
								} else {
									bank_name = this.state.registration.bank_name.value;
								}
							} else {
								if (this.state.registration.bank_name === 'other') {
									if (typeof this.state.registration.bank_name_others === 'object') {
										bank_name = this.state.registration.bank_name_others.value;
									} else {
										bank_name = this.state.registration.bank_name_others;
									}
								} else {
									bank_name = this.state.registration.bank_name;
								}
							}
							// REDIRECT SUMMARY
							let errors = { ...this.state.errors };
							const param = {
								member_id: this.state.member_id,
								nh_auth: this.state.registration.auth_token,
								data: JSON.stringify({
									bank_account: [{
										bank_name: bank_name,
										account_number: this.state.registration.account_number,
										// bank_bic_code: (this.state.registration.bank_name) ? (typeof this.state.registration.bank_name === 'object') ? this.state.master.bank.find(({value}) => value === this.state.registration.bank_name.value).code : this.state.master.bank.find(({value}) => value === this.state.registration.bank_name).code : null,
										account_holder_name: (this.state.registration.account_holder_name) ? this.state.registration.account_holder_name : null,
										account_currency: (this.state.registration.account_currency) ? this.state.registration.account_currency : 0,
									}],
									rdn_bank: bank_name === 'bca' ? bank_name : (typeof this.state.registration.rdn_bank === 'object') ? this.state.registration.rdn_bank.value : this.state.registration.rdn_bank,
									npwp_number: this.state.registration.npwp_number !== null ? this.state.registration.npwp_number : '',
									npwp_registration_date: (this.state.registration.npwp_registration_date) ? this.state.registration.npwp_registration_date : '0001-01-01',
								})
							}

							axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
								if (result.data.status) {
									this.setState({
										redirectSummary: true,
										next_action: false,
									})
								} else {
									errors['global_type'] = 'danger';
									errors['global_message'] = result.data.message;
									this.setState({
										errors: errors,
										next_action: false,
									});
								}
							}, (error) => {
								console.log('/api/member');
								console.log(error);
								errors['global_type'] = 'danger';
								errors['global_message'] = 'Cannot communicate with server.';
								this.setState({
									errors: errors
								});
							});
						}
					}
				});
			} else if (action.field === 'registration_summary_page') {
				// REDIRECT SUBMITTED
				let date = new Date();
				let currentDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getDate();
				let errors = { ...this.state.errors };
				const param = {
					member_id: this.state.member_id,
					nh_auth: this.state.registration.auth_token,
					data: JSON.stringify({
						submitted: 1,
						submitted_date: currentDate,
					})
				}

				axios.post(link_api + '/api/member', queryString.stringify(param), headers).then(result => {
					if (result.data.status) {
						this.setState({
							redirectSubmited: true,
							redirectRegistration: false,
							step: 1,
						})
					} else {
						errors['global_type'] = 'danger';
						errors['global_message'] = result.data.message;
						this.setState({
							errors: errors
						});
					}
				}, (error) => {
					console.log('/api/member');
					console.log(error);
					errors['global_type'] = 'danger';
					errors['global_message'] = 'Cannot communicate with server.';
					this.setState({
						errors: errors
					});
				});
			}
		} else if (action.type === 'clickButton') {
			if (action.field === 'prev') {
				if (this.state.redirectSummary) {
					this.setState({
						redirectSummary: false,
						errors: {
							global_type: '',
							global_message: '',
						}
					})
				} else if (this.state.redirectRegistration && this.state.step === 1) {
					this.setState({
						redirectRegistration: false,
						errors: {
							global_type: '',
							global_message: '',
						}
					})
				} else {
					this.setState({
						step: this.state.step - 1,
						errors: {
							global_type: '',
							global_message: '',
						}
					})
				}
			}
		} else if (action.type === 'reset') {
			this.setState({
				...this.state,
				step: 1,
				member_id: '',
				citizenship: {
					identitynumber: '',
					fullname: '',
					dob: '',
					// identitynumber: 'KITASRUDI1828329',
					// fullname: 'Rudi Tabuti',
					// dob: '1987-05-06',
					// identitynumber: '6828967919756539',
					// fullname: 'BEBIH IRAWAN',
					// dob: '1988-03-09',
					//identitynumber: '6828967919756510',
					//fullname: 'BEBIH IRAWAN',
					//dob: '1988-03-09',
					// identitynumber: '3232656569898989',
					// fullname: 'suci susanti',
					// dob: '1995-02-01',
					// identitynumber: '3173031710920001',
					// fullname: 'ONES LUKITO',
					// dob: '1992-10-17',
					foreigner: false,
					otp: '',
					// otp:'52619504',
					email: '',
					phonenumber: '',
				},
				submitted: {
					submitted: '',
					identitynumber: '',
					fullname: '',
					dob: '',
					ksei_status: '',
					ksei_sre: '',
					ksei_sid: '',
					rdn_status: '',
					rdn_bank: '',
					rdn_number: '',
					rdn_pdf_form_path: '',
				},
				registration: initRegis,
				redirectRegistration: false,
				redirectSubmited: false,
				redirectSummary: false,
				foreignerSubmited: false,
				errors: {
					global_type: '',
					global_message: '',
				},
			})
		} else if (action.type === 'translate') {
			return translations[this.state.lang][action.text];
		}
	}


	async componentDidMount() {
		// Check maintenance status
		if (this.state.maintenance_mode === null) {
			this.checkMaintenanceStatus();
		}

		// Call event tracker (meta pixel, google tag, etc)
		if (window.location.host === 'openaccount.nhsec.co.id') {

			if(typeof window.fbq !== 'undefined') {
				// meta pixel event
				window.fbq('track', 'PageView');
			}
		}

		const ipAddress = await publicIp.v4({ fallbackUrls: ["https://ifconfig.co/ip"] });

		let ref = null;
		if (queryString.parse(window.location.search).ref !== undefined) {
			// ref = queryString.parse(window.location.search).ref.replace(/\D/g, '');
			// only 5 chart allowed
			ref = queryString.parse(window.location.search).ref.replace(/[\W]+/g, '').substring(0, 5);
		}

		// console.log(ref);

		// if(ref!==null) {
		// 	if(ref.search(/[\D]/g) < 0) {
		// 		ref = null;
		// 	}
		// }

		let paramUserId = null;
		let showRefField = false;
		
		if (queryString.parse(window.location.search).ref !== undefined) {
			// paramUserId = queryString.parse(window.location.search).ref.replace(/[\W]+/g, '');
			// only 5 chart allowed
			paramUserId = queryString.parse(window.location.search).ref.replace(/[\W]+/g, '').substring(0, 5);
			showRefField = true;
		}

		const pathEvent = window.location.pathname;
		if (pathEvent.includes('/event')) {
			showRefField = true;
		}

		this.getMasterData();
		let setting_contact = [];
		let setting_web = [];
		let welcome_title, privacy, legal;
		let setting_liveness_limit_container = [];
		let setting_liveness_limit = 0;

		await axios.get(link_api + '/api/setting', { params: { category: 'social' } }).then(result => {
			if (result.data.status) {
				setting_contact = result.data.data;
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/setting', { params: { category: 'web_description' } }).then(result => {
			if (result.data.status) {
				setting_web = result.data.data;
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/information', { params: { slug: 'welcome-to-nh-korindo-sekuritas' } }).then(result => {
			if (result.data.status) {
				welcome_title = result.data.data;
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/information', { params: { slug: 'privacy' } }).then(result => {
			if (result.data.status) {
				privacy = result.data.data;
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/information', { params: { slug: 'legal' } }).then(result => {
			if (result.data.status) {
				legal = result.data.data;
			}
		}, (error) => {
			console.log(error);
		});

		await axios.get(link_api + '/api/setting', { params: { category: 'general_settings' } }).then(result => {
			if (result.data.status) {
				setting_liveness_limit_container = result.data.data;

				// console.log(setting_liveness_limit_container);

				setting_liveness_limit_container.map((v, i, arr) => {
					if(v.key === 'liveness_submit_limit') {
						setting_liveness_limit = !isNaN(parseInt(v.value)) ? parseInt(v.value) : 0;
					}

					return v.value;
				});
			}
		}, (error) => {
			console.log(error);
		});

		this.setState({
			setting_liveness_limit: setting_liveness_limit,
			setting_contact: setting_contact,
			setting_web: setting_web,
			welcome_title: welcome_title ? welcome_title[0] : '',
			privacy: privacy ? privacy[0] : '',
			legal: legal ? legal[0] : '',
			dimensions: {
				width: this.container ? this.container.offsetWidth : '',
				height: this.container ? this.container.offsetHeight : '',
			},
			ip_address: (ipAddress != null) ? ipAddress : '',
			referral_url: window.location.href,
			ref_id: ref !== null ? ref : null,
			user_agent: navigator.userAgent,

			/* if ref_id is provided from url, dont let user to edit */
			user_id: paramUserId !== null ? paramUserId : '',
			user_id_editable: paramUserId === null,
			show_ref_field: showRefField,
		})
	}

	render() {
		const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
		if (!this.state.maintenance_mode && this.state.maintenance_mode !== null) {
			return (
				<Router>
					<Provider value={{ state: this.state, dispatch: this.dispatch }}>
						<Container fluid className="layout">
							<Row>
								<Col className="side-left" lg={4}>
									<div className="sl-top">
										<Link to="/" className="link-logo">
											<img src={logo} className="img-fluid" alt="" />
										</Link>
										<Language
											currentLang={this.state.lang}
											handleChange={(value) => {
												this.setState({
													lang: value
												})
											}
											}
										/>
									</div>
									<div className="sl-middle">
										<div className="slm-content">
											<h1>{this.state.welcome_title.name}</h1>
											{renderHTML(this.state.welcome_title.content)}
											{
												this.state.setting_contact.length > 0 ?
													<YouTube videoId={this.state.setting_contact.find(({ key }) => key === 'featured_youtube_video').value} opts={this.state.optYoutube} />
													:
													''
											}
										</div>
									</div>
									<LayoutFooter data_social={this.state.setting_contact} legal_privacy={this.dispatch({ 'type': 'translate', 'text': 'legal_privacy' })} data_web={this.state.setting_web} />
								</Col>
								<Col className="side-right" lg={8} ref={el => (this.container = el)}>
									<div className="sr-content">
										{/* <Route path="/" exact component={Citizenship} /> */}
										<Route path="/" exact>
											{
												(this.state.redirectRegistration) ?
													<Redirect to="/registration" />
													:
													<Citizenship/>
											}
										</Route>										
										<Route path="/citizenship">
											{
												(this.state.redirectRegistration) ?
													<Redirect to="/registration" />
													:
													<Citizenship/>
											}
										</Route>
										<Route path="/event">
										{
												(this.state.redirectRegistration) ?
													<Redirect to="/registration" />
													:
													<Citizenship/>
											}
										</Route>
										{/* <Route path="/foreign-citizenship">
											{
												(this.state.redirectRegistration) ?
													<Redirect to="/registration" />
													:
													<Citizenship citizenship="foreigner" />
											}
										</Route> */}
										<Route path="/registration">
											<ScrollToTop target="" />
											{
												(!this.state.redirectRegistration) ?
													<Redirect to="/citizenship" />
													:
													(!this.state.redirectSummary) ?
														<Registration />
														:
														<Redirect to="/summary" />
											}
										</Route>
										<Route path="/status">
											<RegistrationStatus />
										</Route>
										<Route path="/summary">
											{
												(this.state.redirectSummary && !this.state.redirectSubmited) ?
													<Summary />
													:
													(this.state.redirectSummary && this.state.redirectSubmited) ?
														<Redirect to="/submitted" />
														:
														<Redirect to="/citizenship" />
											}
										</Route>
										<Route path="/submitted">
											{
												(this.state.redirectSubmited) ?
													<Submitted />
													:
													<Redirect to="/citizenship" />
											}
										</Route>
										<Switch>
											<Route path="/viewer/:param">
												<PdfViewer npwp_number={this.state.registration.npwp_number} />
											</Route>
										</Switch>
										<Route path="/legal-privacy" component={Privacy} />
									</div>
								</Col>
								<Col className="footer-mobile">
									<LayoutFooter data_social={this.state.setting_contact} legal_privacy={this.dispatch({ 'type': 'translate', 'text': 'legal_privacy' })} data_web={this.state.setting_web} />
								</Col>
							</Row>
						</Container>
					</Provider>
				</Router>
			);
		} else if (this.state.maintenance_mode) {
			return (
				<Provider value={{ state: this.state, dispatch: this.dispatch }}>
					<Maintenance />
				</Provider>
			);
		} else {
			return (
				<div></div>
			);
		}
	}
}
